<template>
  <FRcontent  v-if="this.language=='FR'"/>
  <DEcontent  v-else/>
</template>

<style src="../../../components/contents/contract/style.css"></style>

<script>

import { mapState } from 'vuex'; 

export default {
  computed: {
    ...mapState(['language']),
  },
  components: {
    'DEcontent': require('@/components/contents/contract/DE.vue').default,
    'FRcontent': require('@/components/contents/contract/FR.vue').default
  }
}

</script>