<template></template>
<script>
export default {
    created() {
        const language = {
            title: "Cyber Krisen Management", 
            crisistitle: "LEITUNG DES KRISENSTABS", 
            crisislable: "Wir übernehmen die ganzheitliche Steuerung aller Krisenreaktionsmaßnahmen - von der Analyse, über das Schadenmanagement, bis hin zur Wiederherstellung Ihres Geschäftsbetriebs. ", 
            cybertitle: "INDIVIDUELLES IT-SPEZIALISTENTEAM", 
            cyberlabel: "Bei Eintritt eines Cyber-Vorfalls in Ihrem Unternehmen stellen wir für Sie ein individuelles Team von IT-Spezialisten aus unserem Experten-Netzwerk zusammen.", 
            protecttitle: "DATENSCHUTZ & STRAFVERFOLGUNG", 
            protectlabel: "Beratung zu Datenschutzthemen, Kommunikation mit der Datenschutzaufsicht, LKA/BKA, Polizei und die Bereitstellung von Fachanwälten werden ebenfalls durch unser Experten-Netzwerk abgedeckt."
        };
        this.$emit('passLanguage', language)
    }
}
</script>