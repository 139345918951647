<template></template>
<script>

export default {
    created() {
        const language = {
            title: "Cyber Sofort Hilfe",
            subtitle: "DIE LÖSUNG FÜR ALLE, DIE BISHER NOCH KEINE CYBER-VERSICHERUNG HABEN ODER DENEN VERSICHERUNGSSCHUTZ BISHER VERWEHRT WORDEN IST."
        };
        this.$emit('passLanguage', language)
    }
}
</script>