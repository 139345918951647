<template>
    <div class="p-yscroll-content">
        <p><b>Notification du fournisseur et informations obligatoires conformément à l’article 5 de la loi allemande sur les télémédias (TMG).</b></p>
        <p>Le prestataire de services au sens de l’article 5 de la loi allemande sur les télémédias est:</p>
        <p>COGITANDA Claims Services GmbH<br>Münstereifeler Str. 1<br>53505 Altenahr (Kreuzberg)<br>Allemagne</p>
        <p>
            <a href="mailto:info@cogitanda.com">info@cogitanda.com</a><br>
            Tel.:<a href="tel:+4922199575200">+49(0)221 995 752 06</a><br>Fax.: +49(0)221 669 503 59</p>
        <p><b>Représenté par les directeurs:</b><br>Nicolai Wojciechowski<br>Tim Sievers<br>Christian Sagawe</p>
        <p>
            <b>Siège social de la société:</b>Altenahr<br />
            <b>Tribunal de commerce: </b>Amtsgericht Koblenz<br />
            <b>Numéro au registre du commerce et des sociétés: </b>HRB 25672<br>
            <b>Numéro TVA intracommunautaire: </b>DE 313894877
        </p>
        <p><b>Autorité de surveillance/Autorité d’agrément /Association professionnelle responsable:</b><br>IHK Koblenz, Schlossstr. 2, 56068 Koblenz, Allemagne, <a href="https://www.ihk.de/koblenz/" target="_blank" rel="nofollow">www.ihk.de/koblenz/</a></p>
        <p><b>Clause de non-responsabilité:</b><br>Malgré un examen minutieux du contenu, nous n'assumons aucune responsabilité pour le contenu des liens externes. Les exploitants des sites liés sont responsables de leur contenu.</p>
        <p><b>Que signifie COGITANDA?</b><br>Que signifie le nom COGITANDA? COGITANDA n'est pas un terme inventé, il vient du latin. Nous traduisons ce gérondif du mot "cogitare" par: "Les choses auxquelles on devrait penser".</p>
        <p><b>Marque déposée:</b><br><b>COGITANDA</b>est protégée en Allemagne, au Royaume-Uni et dans toute l'Union européenne. COGITANDA est une marque déposée.</p>
        <p><b>Mise en oeuvre du site web:</b><br>COGITANDA Claims Services GmbH<br>Münstereifeler Str. 1<br>53505 Altenahr (Kreuzberg)<br>Allemagne<br></p>
    </div>
</template>
<script>

export default {
}

</script>