<template></template>
<script>
export default {
    created() {
        const language = {
            title: "Cyber Aide d’Urgence",
            subtitle: "LA SOLUTION POUR TOUS CEUX QUI NE VEULENT PAS OU NE PEUVENT PAS ENCORE S’ASSURER EN CYBER."
        };
        this.$emit('passLanguage', language)
    }
}
</script>