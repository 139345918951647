<template>
    <div class="p-yscroll-content">
        <p>Cette information sur la protection des données pour les partenaires commerciaux vous informe sur le traitement de vos données personnelles par le groupe COGITANDA.</p>
        <p>Conformément à l'article 4, point 1 du RGPD, vos données personnelles comprennent toutes les informations qui se rapportent ou peuvent se rapporter à votre personne, en particulier au moyen de l'attribution d'un identifiant tel qu'un nom ou un numéro d'organisation ou de client, avec lequel votre personne peut être identifiée.</p>
        <p>Nous fournissons cette information sur la protection des données à nos partenaires commerciaux ("Communication") afin d'expliquer, en tant que responsable, les processus de traitement des données concernant nos fournisseurs, clients et partenaires commerciaux (collectivement, "Partenaires commerciaux") et leurs employés qui sont en relation avec le groupe COGITANDA.</p>
        <h2>Champ d'application :</h2>
        <p>Cet avis s'applique si vous êtes un partenaire commercial indépendant de l'une des sociétés du groupe COGITANDA mentionnées dans les mentions légales (par exemple, en tant que courtier coopératif) ou si vous êtes un employé d'un partenaire commercial agissant au nom du partenaire commercial avec le groupe COGITANDA.</p>
        <h2>Catégories de données à caractère personnel et sources de données :</h2>
        <p>Le groupe COGITANDA traite les données personnelles suivantes provenant de votre entreprise ou de tiers (par ex. votre supérieur hiérarchique, les autorités ou les structures publiques) :</p>
        
        <ul>
            <li>
                Données personnelles relatives aux partenaires commerciaux indépendants : Nom, coordonnées professionnelles, services ou produits proposés, informations contractuelles, contenu des communications (comme les e-mails ou les lettres commerciales), informations de paiement, informations de facturation et historique de la relation commerciale.
            </li>
            <li>
                Données personnelles relatives aux employés d'un partenaire commercial : nom, coordonnées professionnelles, nom de l'employeur, titre/poste et contenu des communications (comme les e-mails ou les lettres commerciales).
            </li>
        </ul>

        <h2>Buts du traitement des données, base juridique et conséquences :</h2>
        <p>Vos données à caractère personnel sont traitées aux fins de l'exécution de la relation contractuelle avec le partenaire commercial (y compris l'exécution des obligations contractuelles de prestation, le traitement des factures, la communication et le respect des dispositions légales) pour les activités de marketing et de CRM et pour la prévention de la fraude.</p>

        <ul>
            <li>
                Exécution de la relation contractuelle avec le partenaire commercial (art. 6, lettre b du RGPD) ;
            </li>
            <li>
                Intérêt légitime de l'une des entreprises du groupe COGITANDA ou d'un tiers (par ex. autorités publiques ou tribunaux) (art. 6, lettre f du RGPD). L'intérêt légitime peut être notamment l'échange d'informations à l'échelle du groupe COGITANDA, les activités de marketing et de gestion de la relation client, la prévention de la fraude, l'utilisation abusive des technologies de l'information, le blanchiment d'argent, l'exploitation d'un système d'alerte, la sécurité physique, la sécurité des technologies de l'information et du réseau, les enquêtes internes ou encore les activités potentielles de fusion et d'acquisition.
            </li>
            <li>
                Consentement (art. 6, lettre a du RGPD) ;
            </li>
            <li>
                Respect d'obligations légales (art. 6, lettre c du RGPD) ;
            </li>
        </ul> 

        <p>La mise à disposition de données à caractère personnel est nécessaire et volontaire pour la conclusion et/ou l'exécution du contrat de partenariat commercial. Toutefois, si vous ne fournissez pas ces données, cela pourrait retarder ou même rendre impossible la gestion du partenaire commercial et les processus administratifs.</p>

        <h2>Catégories de destinataires :</h2>
        <p>Les sociétés du groupe COGITANDA peuvent faire appel à des prestataires de services qui agissent en tant qu’opérateurs afin de fournir un soutien informatique et d'autres services administratifs (par exemple, des prestataires de services qui fournissent une assistance en matière de comptabilité fournisseurs ou d'hébergement informatique et de support de maintenance). Ces prestataires de services peuvent avoir accès à vos données à caractère personnel dans la mesure où cela est nécessaire pour la fourniture de ces services.</p>
        <p>En outre, COGITANDA Risk Prevention GmbH, COGITANDA Claims Services GmbH, COGITANDA Managed Services GmbH, COGITANDA Insurance Services GmbH, COGITANDA Austria GmbH et COGITANDA France SAS peuvent transférer vos données personnelles à leur société mère, COGITANDA Dataprotect AG, dans le but d'aider au traitement des opérations, à la gestion et à l'analyse des relations et interactions avec les clients et à l'assistance informatique générale associée.</p>
        <p>En concluant les accords de transfert de données appropriés, basés sur le traitement des commandes, et en prenant des mesures pour garantir un niveau de protection des données adéquat, nous avons établi que les sociétés du groupe COGITANDA fourniront un niveau de protection des données approprié.</p>
        <p>Tout accès à vos données personnelles est limité aux personnes qui ont besoin de ces informations pour mener à bien leurs activités.</p>
        <p>Les sociétés du groupe COGITANDA peuvent divulguer vos données personnelles si les autorités gouvernementales, les tribunaux, les autorités étrangères ou des tiers similaires le demandent ou l'exigent.</p>

        <h2>Durée de conservation des données :</h2>
        <p>Les données personnelles sont conservées aussi longtemps que nécessaire par le groupe COGITANDA et nos prestataires de services jusqu'à ce que nos obligations soient remplies. Les données sont sauvegardées aussi longtemps que nécessaire pour la finalité de traitement conformément à la loi sur la protection des données en vigueur. Dès que le groupe COGITANDA n'a plus besoin des données pour remplir ses obligations contractuelles ou légales, celles-ci seront supprimées de nos systèmes et registres et/ou des mesures seront prises pour que vos données personnelles soient correctement anonymisées afin qu'elles ne soient plus identifiables, sauf si nous devons conserver les données personnelles vous concernant pour respecter les obligations légales ou réglementaires auxquelles les sociétés du groupe COGITANDA sont soumises. Par exemple, les délais de conservation légaux découlant du code de commerce et du code fiscal, qui sont généralement compris entre 6 et 10 ans, ou la préservation de preuves dans le cadre du délai de prescription, qui est généralement de 3 ans, mais peut aller jusqu'à 30 ans.</p>
        
        <h2>Vos droits :</h2>
        <p>Si vous avez donné votre accord concernant certaines activités de traitement, vous pouvez le révoquer à tout moment avec effet pour la suite des opérations. Cette révocation n'affectera pas le traitement antérieur des données.</p>
        <p>Conformément à la législation applicable en matière de protection des données, vous avez le droit : a) de consulter vos données à caractère personnel ; b) de demander la rectification de vos données à caractère personnel ; c) de demander la suppression de vos données à caractère personnel ; d) d'imposer des restrictions au traitement de vos données à caractère personnel ; e) de demander le transfert de vos données à caractère personnel ; f) de vous opposer au traitement de vos données à caractère personnel. Veuillez noter que les droits susmentionnés peuvent être limités par la législation nationale.</p>
        <p>Droit d'accès : vous avez le droit d'obtenir de notre part la confirmation que nous traitons ou non vos données à caractère personnel et d'accéder à ces données. Les informations d'accès comprennent, entre autres, la finalité du traitement, les catégories de données à caractère personnel, les personnes concernées et les destinataires ou les catégories de destinataires. Il ne s'agit toutefois pas d'un droit absolu et les intérêts d'autres personnes peuvent limiter votre droit d'accès.</p>
        <p>Vous avez le droit de recevoir une copie des données à caractère personnel qui sont traitées.</p>
        <p>Pour les copies supplémentaires qui vous sont demandées, nous avons le droit de facturer des frais raisonnables pour couvrir les coûts administratifs.</p>
        <p>Droit de rectification : vous avez le droit de nous demander de rectifier les données à caractère personnel inexactes vous concernant. En fonction de la finalité du traitement, vous avez le droit d'obtenir que des données à caractère personnel incomplètes soient complétées au moyen d'une déclaration supplémentaire.</p>
        <p>Droit à l'effacement (droit à l'oubli) : Dans certaines circonstances, vous avez le droit de demander l'effacement des données à caractère personnel vous concernant.</p>
        <p>Droit à un traitement limité : dans certaines circonstances, vous avez le droit d'obtenir de notre part une limitation du traitement de vos données à caractère personnel. Dans ces circonstances, les données en question seront marquées et traitées uniquement dans un but précis.</p>
        <p>Droit au transfert de données : Dans certaines circonstances, vous avez le droit de recevoir les données à caractère personnel que vous nous avez fournies dans un format structuré, fréquemment utilisé et lisible par machine, et vous avez le droit de transmettre ces données à une autre entreprise sans entrave.</p>
        <p>Pour exercer vos droits, contactez-nous comme indiqué dans la section "Questions".</p>
        <p>Vous avez en outre le droit de déposer une plainte auprès de l'autorité de surveillance compétente.</p>

        <h2>Droit d'opposition conformément à l'article 21 du RGPD</h2>
        <p>La personne concernée a le droit de s'opposer à tout moment, pour des raisons tenant à sa situation particulière, au traitement des données à caractère personnel la concernant, effectué sur la base de l'article 6, paragraphe 1, points e) ou f).</p>
        <p>Étant donné que les sociétés du groupe COGITANDA traitent et utilisent vos données à caractère personnel principalement aux fins de la relation contractuelle avec vous, les sociétés du groupe ont en principe un intérêt légitime à traiter vos données, ce qui, en revanche, annulera votre demande de limitation.</p>
      
        <p>Pour exercer ce droit, veuillez nous contacter comme indiqué dans la section "Questions".</p>
        <p>COGITANDA Claims Services GmbH n'effectue pas de prise de décision automatique.</p>

        <h2>Questions :</h2>
        <p>Vous pouvez contacter le responsable de la protection des données de COGITANDA Claims Services GmbH aux coordonnées suivantes :  <a href="mailto:privacy@cogitanda.com">privacy@cogitanda.com</a></p>
    </div>
</template>
<script>

export default {
}

</script>