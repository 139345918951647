<script setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import InputNumber from 'primevue/inputnumber';
</script>

<template>
    <div class="inner lightgraybkg">
        <div class="d-floatleft">
            <div class="d-content form" id="step01">
                <div class="title">
                    BERECHNUNG DER JAHRESGEBÜHR
                </div>
                    
                <div class="p-width100">
                    <input type="hidden"
                        id="companylocation" />
                    <div class="locationButton">
                        <img class="countryButton deButton" :src="imagePath + 'contractform/country-DE-'+((country=='DE')? '01':'00')+'.png?'+updateView" @click="changeCountry('DE')"/> 
                        <img class="countryButton atButton" :src="imagePath + 'contractform/country-AT-'+((country=='AT')? '01':'00')+'.png?'+updateView" @click="changeCountry('AT')"/>
                    </div>
                    <span>Land (Firmensitz)</span>
                </div>

                <div class="p-width100">
                    <select 
                        v-model="stepData.companyselection"  
                        class="p-inputtext p-component"
                        @change="companyselectionOption"
                        >
                            <option value="default">Bitte auswählen</option>
                            <option value="Unternehmen">Unternehmen</option>
                            <option value="Banken">Banken</option>
                            <option v-if="country=='AT'" value="GemeindenKommunen">Gemeinden</option>
                            <option v-if="country=='DE'" value="GemeindenKommunen">Kommunen</option>
                            <option value="Krankenkassen">Krankenkassen</option>
                    </select>
                    <span>Gebühren-Kategorie *</span>
                </div>
                                
                <div class="p-width100" v-if="stepData.companyselection != 'default'">
                    <InputNumber 
                        v-if="stepData.companyselection==='Krankenkassen'"
                        v-model="stepData.companyannualsales" 
                        inputId="currency-germany" 
                        class="p-component"
                        @input="inputSearchPrices"
                        locale="de-DE" 
                        :minFractionDigits="0" />
                    <InputNumber 
                        v-if="stepData.companyselection!='Krankenkassen'"
                        v-model="stepData.companyannualsales" 
                        inputId="currency-germany" 
                        class="p-component"
                        @input="inputSearchPrices"
                        mode="currency" 
                        currency="EUR" 
                        locale="de-DE" />
                    <span>{{ stepData.companyselectionText }}</span>
                </div>
                                
                <div class="p-width50L">
                    <VueDatePicker 
                        v-model="stepData.companyfrom" 
                        locale="de" 
                        teleport-center  
                        cancelText="✕" 
                        selectText="✔" 
                        :format="format"
                    />
                    <span>Geschäftsjahr von *</span>
                </div>
                <div class="p-width50R">
                    <VueDatePicker 
                        v-model="stepData.companyto" 
                        locale="de" 
                        teleport-center  
                        cancelText="✕" 
                        selectText="✔" 
                        :format="format"
                    />
                    <span>bis *</span>
                </div>
                <div class="d-floatclear"></div>

                <div class="p-width100">
                    <span class="cost" v-if="stepData.yearlyCost!=''">Ihre Jahresgebühr beträgt {{ stepData.yearlyCost }}</span>
                </div>
            </div>
        </div>
        <div class="d-floatright">
            <div class="d-content form" id="formBoard">
                <div class="prices">
                    
                    <div class="row" v-if="stepData.companyselection=='Unternehmen'">
                        <div class="col100 tab-title tab-border">
                            UNTERNEHMEN 
                        </div>
                        <div class="col50 no-padding-margin">
                            <div class="tab-subtitle tab-border">JAHRESUMSATZ BIS ZU</div>
                            <div class="tab-content tab-border">250.000 Euro</div>
                            <div class="tab-content tab-border">1 Mio. Euro</div>
                            <div class="tab-content tab-border">5 Mio. Euro</div>
                            <div class="tab-content tab-border">50 Mio. Euro</div>
                            <div class="tab-content tab-border">250 Mio. Euro</div>
                            <div class="tab-content tab-border">über 250 Mio. Euro</div>
                        </div>
                        <div class="col50 no-padding-margin">
                            <div class="tab-subtitle tab-border">NETTOJAHRESGEBÜHR <span>(zzgl. MwSt.)</span></div>
                            <div class="tab-content tab-border">49 Euro</div>
                            <div class="tab-content tab-border">99 Euro</div>
                            <div class="tab-content tab-border">199 Euro</div>
                            <div class="tab-content tab-border">399 Euro</div>
                            <div class="tab-content tab-border">799 Euro</div>
                            <div class="tab-content tab-border">auf Anfrage</div>
                        </div>
                    </div>
                    <div class="row" v-if="stepData.companyselection=='Banken'">
                        <div class="col100 tab-title tab-border">
                            BANKEN
                        </div>
                        <div class="col50 no-padding-margin">
                            <div class="tab-subtitle tab-border">BILANZSUMME BIS ZU</div>
                            <div class="tab-content tab-border">200 Mio. Euro</div>
                            <div class="tab-content tab-border">350 Mio. Euro</div>
                            <div class="tab-content tab-border">500 Mio. Euro</div>
                            <div class="tab-content tab-border">2,5 Mrd. Euro</div>
                            <div class="tab-content tab-border">über 2,5 Mrd. Euro</div>
                        </div>
                        <div class="col50 no-padding-margin">
                            <div class="tab-subtitle tab-border">NETTOJAHRESGEBÜHR <span>(zzgl. MwSt.)</span></div>
                            <div class="tab-content tab-border">999 Euro</div>
                            <div class="tab-content tab-border">1.999 Euro</div>
                            <div class="tab-content tab-border">2.499 Euro</div>
                            <div class="tab-content tab-border">4.999 Euro</div>
                            <div class="tab-content tab-border">auf Anfrage</div>
                        </div>
                    </div>
                    <div class="row" v-if="stepData.companyselection=='Krankenkassen'">
                        <div class="col100 tab-title tab-border">
                            KRANKENKASSEN
                        </div>
                        <div class="col50 no-padding-margin">
                            <div class="tab-subtitle tab-border">ANZAHL MITGLIEDER BIS ZU</div>
                            <div class="tab-content tab-border">450.000</div>
                            <div class="tab-content tab-border">750.000</div>
                            <div class="tab-content tab-border">1 Mio.</div>
                            <div class="tab-content tab-border">2,5 Mio.</div>
                            <div class="tab-content tab-border">über 2,5 Mio.</div>
                        </div>
                        <div class="col50 no-padding-margin">
                            <div class="tab-subtitle tab-border">NETTOJAHRESGEBÜHR <span>(zzgl. MwSt.)</span></div>
                            <div class="tab-content tab-border">999 Euro</div>
                            <div class="tab-content tab-border">1.999 Euro</div>
                            <div class="tab-content tab-border">2.999 Euro</div>
                            <div class="tab-content tab-border">4.999 Euro</div>
                            <div class="tab-content tab-border">auf Anfrage</div>
                        </div>
                    </div>
                    <div class="row" v-if="stepData.companyselection=='GemeindenKommunen'">
                        <div  v-if="country=='AT'" class="col100 tab-title  tab-border">
                            GEMEINDEN
                        </div>
                        <div v-else class="col100 tab-title  tab-border">
                            KOMMUNEN
                        </div>
                        <div class="col50 no-padding-margin">
                            <div class="tab-subtitle tab-border">HAUSHALTSVOLUMEN BIS ZU</div>
                            <div class="tab-content tab-border">100 Mio. Euro</div>
                            <div class="tab-content tab-border">150 Mio. Euro</div>
                            <div class="tab-content tab-border">200 Mio. Euro</div>
                            <div class="tab-content tab-border">300 Mio. Euro</div>
                            <div class="tab-content tab-border">über 300 Mio. Euro</div>
                        </div>
                        <div class="col50 no-padding-margin">
                            <div class="tab-subtitle tab-border">NETTOJAHRESGEBÜHR <span>(zzgl. MwSt.)</span></div>
                            <div class="tab-content tab-border">999 Euro</div>
                            <div class="tab-content tab-border">1.999 Euro</div>
                            <div class="tab-content tab-border">2.999 Euro</div>
                            <div class="tab-content tab-border">4.999 Euro</div>
                            <div class="tab-content tab-border">auf Anfrage</div>
                        </div>
                    </div>

                </div>
                <div class="tab-error" v-if="yearlyCostError[0] && yearlyCostError[1] === 1">
                    Aufgrund Ihrer Unternehmensgröße, bitten wir Sie eine individuelle Anfrage über unser <a @click="handleClose">Kontaktformular</a> zu stellen.
                </div>
                <div class="tab-error" v-if="stepData.stepsTimeOut===true">
                    Aufgrund der 5-minütigen Inaktivität beim Ausfüllen des Vertrags wurde Ihnen eine Zeitüberschreitung eingeräumt, um die Sicherheit Ihrer Daten zu gewährleisten.
                </div>
                <div class="tab-error" v-if="stepData.stepPass==false">
                    Bitte füllen Sie alle Pflichtfelder aus, um fortzufahren.
                </div>
            </div>
        </div>
        <div class="d-floatclear form" style="padding: 0!important; height: 1px!important">&nbsp;</div>
    </div>
</template>

<style src="../../../../components/tools/contractform/DE/step01.css"></style>
<script>

import { mapState, mapActions } from 'vuex'; 

export default {
    data() {
        return {
            yearlyCostError: [false, 0],
            stepName: 'step01',
            stepData: {
                companyselection: 'default',
                companyselectionText: '-',
                companyannualsales: 0,
                yearlyCost: '',
                companyfrom: '',
                companyto: '',
                pass: false,
                stepsTimeOut: false
            },
            pricesList: {
                category1: {
                    name: 'unternehmen',
                    prices: [
                        {range: 250000,     value:  49.00},
                        {range: 1000000,    value:  99.00},
                        {range: 5000000,    value: 199.00},
                        {range: 50000000,   value: 399.00},
                        {range: 250000000,  value: 799.00}
                    ]
                },
                category2: {
                    name: 'banken',
                    prices: [
                        {range: 200000000,    value:  999.00},
                        {range: 350000000,    value:  1999.00},
                        {range: 500000000,    value:  2499.00},
                        {range: 2500000000,   value:  4999.00}
                    ]
                },
                category3: {
                    name: 'gemeindenkommunen',
                    prices: [
                        {range: 100000000,    value:  999.00},
                        {range: 150000000,    value: 1999.00},
                        {range: 200000000,    value: 2999.00},
                        {range: 300000000,    value: 4999.00}
                    ]
                },
                category4: {
                    name: 'gemeinden',
                    prices: [
                        {range: 100000000,    value:  999.00},
                        {range: 150000000,    value: 1999.00},
                        {range: 200000000,    value: 2999.00},
                        {range: 300000000,    value: 4999.00}
                    ]
                },
                category5: {
                    name: 'krankenkassen',
                    prices: [
                        {range:  450000,    value:  999.00},
                        {range:  750000,    value: 1999.00},
                        {range: 1000000,    value: 2999.00},
                        {range: 2500000,    value: 4999.00}
                    ]
                }
            }
        }
    },
    methods: {
        ...mapActions(['updateContractSteps']),
        changeCountry(country) {
            this.$store.dispatch('updateCountry', country);
        },
        changeContractSteps(newContractSteps) {
            this.$store.dispatch('updateContractSteps', newContractSteps);
        },
        handleClose() {
            window.location.href = '/#formula';
            this.$emit('visibleClose', false);
        },
        companyselectionOption() {

           if(this.stepData.companyannualsales === null) {
            this.stepData.companyannualsales = 0.00;
           }

           if(this.stepData.companyselection === 'Banken') {
                this.stepData.companyselectionText = 'Bilanzsumme(€) *';
            } else if(this.stepData.companyselection === 'GemeindenKommunen') {
                this.stepData.companyselectionText = 'Haushaltsvolumen(€) *';
            } else if(this.stepData.companyselection === 'Krankenkassen') {
                this.stepData.companyannualsales = Math.round(this.stepData.companyannualsales * 100) / 100;
                this.stepData.companyselectionText = 'Mitgliederzahl *';
            } else if(this.stepData.companyselection === 'Unternehmen') {
                this.stepData.companyselectionText = 'Jahresumsatz(€) *';
            } else {
                this.stepData.companyselectionText = '-';
                this.stepData.companyannualsales = '';
                this.stepData.yearlyCost = '';
            }
        },
        format(date) {
            const day = (date.getDate() < 10) ? '0' + date.getDate() : date.getDate();
            const month = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
            const year = date.getFullYear();

            return `${day}.${month}.${year}`;
        },
        inputSearchPrices(values) {

            let matchedCategory = null;

            for (const [categoryName, category] of Object.entries(this.pricesList)) {
                if (category.name.toLowerCase() === this.stepData.companyselection.toLowerCase()) {
                    
                    matchedCategory = category;
                    break; 
                }
            }

            if (matchedCategory) {

                //This is if companyannualsales.value is blank or Zero, no reason to continue
                if((values.value * 1) == 0) {
                    this.stepData.yearlyCost = '';
                    this.yearlyCostError = [false, 1];

                    this.stepData = {...this.stepData, yearlyCost: this.stepData.yearlyCost, yearlyCostError: this.yearlyCostError, companyannualsales: 0, pass: true};
                    this.changeContractSteps({step01: this.stepData});
                    
                    return;
                }

                
                if(values.value > matchedCategory.prices[matchedCategory.prices.length - 1].range) {
                    this.stepData.yearlyCost = '';
                    this.yearlyCostError = [true, 1];

                    this.stepData = {...this.stepData, yearlyCost: this.stepData.yearlyCost, yearlyCostError: this.yearlyCostError, pass: false};
                    this.changeContractSteps({step01: this.stepData});
                    
                    return;
                }

                for (let i = matchedCategory.prices.length - 1; i >= 0; i--) {
                    const price = matchedCategory.prices[i];
                    if (values.value <= price.range) {
                        this.stepData.yearlyCost = `${price.value} €`;
                        this.yearlyCostError = [false, 1];

                        this.stepData = {...this.stepData, companyannualsales: (this.stepData.companyannualsales * 1)};
                        this.stepData = {...this.stepData, yearlyCost: this.stepData.yearlyCost, yearlyCostError: this.yearlyCostError, pass: true};

                        this.changeContractSteps({step01: this.stepData});

                    }
                }
                
            }
        },
    },
    computed: {
        ...mapState(['imagePath', 'updateView', 'country', 'contractSteps']),
    },
    watch: {
        'contractSteps': {
            handler(newdata) {
                this.stepData = newdata.step01
            }
        }
    },
    mounted() {

        if(!this.contractSteps.hasOwnProperty(this.stepName)){

            this.stepData = {...this.stepData};
            this.changeContractSteps({step01: this.stepData});

        } else {
            this.stepData = this.contractSteps.step01;
        }

    },
}
</script>