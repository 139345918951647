<template>
  <div id="cybercrisis" class="bkg_turkish">
    <component :is="selectedLanguage" @passLanguage="setLanguage" />
    <div class="content">
      <h1>{{ locallanguage.title  }}</h1>
      <div class="tribles"> 
        <div class="items">
          <h4>{{ locallanguage.crisistitle }}</h4>
          <p>{{ locallanguage.crisislable }}</p>
        </div>
        <div class="items">
          <h4>{{ locallanguage.cybertitle }}</h4>
          <p>{{ locallanguage.cyberlabel }}</p>
        </div>
        <div class="items">
          <h4>{{ locallanguage.protecttitle }}</h4>
          <p>{{ locallanguage.protectlabel }}</p>
        </div>
      </div>
      
    </div>
  </div>
</template>

<style src="../../../components/parts/crisismanagement/style.css"></style>
    
<script>

import { mapState } from 'vuex'; 

export default {
  data() {
    return {
      locallanguage: {
        title: '', crisistitle: '', crisislable: '', cybertitle: '', cyberlabel:'', protecttitle: '', protectlabel: ''
      }
    }
  },
  computed: {
    ...mapState(['imagePath',  'updateView', 'language']),
    selectedLanguage() {
        return this.language+'component';
    },
  },
  methods: {
    setLanguage(locallanguage) {
      this.locallanguage = locallanguage
    }
  },
  components: {
    'DEcomponent': require('@/components/parts/crisismanagement/DE.vue').default,
    'FRcomponent': require('@/components/parts/crisismanagement/FR.vue').default
  }
}
</script>