<template></template>
<script>
export default {
    created() {
        const language = {
            imprint: 'IMPRESSUM', 
            dataprotection: 'DATENSCHUTZ (ALLGEMEIN)', 
            dataprotectionbusiness: 'DATENSCHUTZ (GESCHÄFTSPARTNER)',
            dataprotectionbusinesstitle: 'DATENSCHUTZ (GESCHÄFTSPARTNER)'
        };
        this.$emit('passLanguage', language)
    }
}
</script>