<template></template>
<script>
export default {
    created() {
        const language = {
            aboutus: "A PROPOS",
            contact: "CONTACT",
            cyberLogoTitle: "COGITANDA® Cyber Aide d’Urgence",
            cogitandaConnect: "Cyber Assurance"
        };
        this.$emit('passLanguage', language)
    }
}
</script>