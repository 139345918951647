<template>
  <div id="services" class="bkg_white">
    <component :is="selectedLanguage" @passLanguage="setLanguage" />
    <div class="content">
      <div align="center">
        <img class="icon" width="177" height="139" alt="COGITANDA® Cyber Krisen Management" :src="imagePath + 'services/icon_hs.webp?'+updateView"/>
      </div>
      <p v-html="locallanguage.label"></p>
    </div>
  </div>
</template>
    
<style src="../../../components/parts/services/style.css"></style>

<script>

import { mapState } from 'vuex'; 

export default {
  data() {
    return {
      locallanguage: {
        label: ''
      }
    }
  },
  computed: {
    ...mapState(['imagePath',  'updateView', 'language']),
    selectedLanguage() {
        return this.language+'component';
    },
  },
  methods: {
    setLanguage(locallanguage) {
      this.locallanguage = locallanguage
    }
  },
  components: {
      'DEcomponent': require('@/components/parts/services/DE.vue').default,
      'FRcomponent': require('@/components/parts/services/FR.vue').default
  }

}
</script>