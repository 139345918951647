<template>
    <div class="inner lightgraybkg">
        <div class="d-floatleft">
            <div class="d-content form" id="step02">
                <div class="title">
                    FIRMENDATEN / RECHNUNGSANSCHRIFT
                </div>
                <div class="p-width100">
                    <input 
                        v-model="stepData.companyname"
                        id="companyname"
                        input="text" 
                        class="p-inputtext p-component reqsubmit"
                        />
                    <span>Firmenname *</span>
                </div>
                <div class="p-width100" >
                    <input 
                        v-model="stepData.companystreetnumber"
                        id="companystreetnumber" 
                        type="text" 
                        class="p-inputtext p-component reqsubmit"
                        />
                    <span>Straße / Nr. *</span>
                </div>
                <div class="p-width100">
                    <input 
                        v-model="stepData.companypostcity"
                        id="companypostcity"  
                        type="text" 
                        class="p-inputtext p-component reqsubmit"
                        />
                    <span>PLZ / Ort *</span>
                </div>

                <div class="p-width100">
                    
                    <div class="p-inputtext p-component">
                        {{ (country==='DE')? 'DEUTSCHLAND': 'ÖSTERREICH' }}
                    </div>
                    <span>Land (Firmensitz) *</span>
                </div>

                <div class="p-width50L">
                    <select 
                        v-model="stepData.companylegalform"
                        class="p-inputtext p-component">
                            <option value="AG">AG</option>
                            <option value="e.K.">e.K.</option>
                            <option value="Einzelunternehmen">Einzelunternehmen</option>
                            <option value="GbR">GbR</option>
                            <option value="Gmbh">Gmbh</option>
                            <option value="GmbH & Co KO">GmbH & Co KO</option>
                            <option value="KG">KG</option>
                            <option value="OHG">OHG</option>
                            <option value="UG">UG</option>
                            <option value="">bitte auswählen</option>
                    </select>
                    <span>Rechtsform *</span>
                </div>
                
                <div class="p-width50R">
                    <input 
                        v-model="stepData.companyemployees"
                        id="companyemployees"  
                        class="p-inputtext p-component reqsubmit"
                        type="number"
                        /><br/>
                    <span>Mitarbeiterzahl *</span>
                </div>
                <div class="d-floatclear"></div>

                <div class="p-width100" >
                    <input 
                        v-model="stepData.companyregister"
                        id="companyregister"  
                        type="text"
                        class="p-inputtext p-component reqsubmit"
                        />
                        <span v-if="country=='AT'">Firmenbuchnummer *</span>
                        <span v-else>Handelsregister-Nr. *</span>
                </div>
                <div class="p-width100" >
                    <input
                        v-model="stepData.companytax"
                        id="companytax"  
                        type="text"
                        class="p-inputtext p-component reqsubmit"
                        />
                        <span v-if="country=='AT'">UID-Nummer *</span>
                        <span v-else>Umsatzsteuer-ID *</span>
                </div>
            </div>
        </div>
        <div class="d-floatright">
            <div class="d-content form" id="formBoard">
                <div class="tab-error" v-if="stepData.stepPass==false">
                    Bitte füllen Sie alle Pflichtfelder aus, um fortzufahren.
                </div>
            </div>
        </div>
        <div class="d-floatclear form" style="padding: 0!important; height: 1px!important">&nbsp;</div>
    </div>  
</template>
  
<style src="../../../../components/tools/contractform/DE/step02.css"></style>
  
<script>

import { mapState, mapActions } from 'vuex'; 

export default {
    data() {
        return {
            stepName: 'step02',
            stepData: {
                companyname: '',
                companystreetnumber: '',
                companypostcity: '',
                companycountry: '',
                companylegalform: '',
                companyemployees: 0,
                companyregister: '',
                companytax: '',
            }
        }
    },
    computed: {
        ...mapState(['imagePath', 'updateView', 'country', 'contractSteps']),
    },
    watch: {
        'contractSteps': {
            handler(newdata) {
                this.stepData = newdata.step02;
            }
        }
    },
    methods: {
        ...mapActions(['updateContractSteps']),
        changeContractSteps(newContractSteps) {
            this.$store.dispatch('updateContractSteps', newContractSteps);
        },
        changeCountry(country) {
            this.$store.dispatch('updateCountry', country);
        },
    }, 
    mounted() {
        if(!this.contractSteps.hasOwnProperty('step02')){

            this.stepData = {...this.stepData, companycountry: this.country};
            this.changeContractSteps({step02: this.stepData});

        } else {
            this.stepData = this.contractSteps.step02;
        }
    },
}
</script>