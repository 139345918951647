import { createStore } from "vuex";

export default createStore({
    state: {
        currentEnvironment: '',
        imagePath: 'https://cogitanda-landingpage.s3.eu-central-1.amazonaws.com/CSH/-IMAGEPATH-/images/',
        language: 'DE',
        country: 'DE',
        updateView: '',
        contractSteps: {},
        contractStepsTimeStamp: 0,
        contractStepsRest: false,
        selectedStep: 1,
        cookies: {
            status: null,
            days: 30
        },
        gtag: {
            TEST: 'G-RH55XE771M',
            DE: 'G-1T3VQK1TLW',
            FR: 'G-BN5N7PQF2K'
        }
    },
    mutations: {
        setEnvironment(state, newValue) {
            state.currentEnvironment = newValue.environment;
        },
        setImagePath(state, newValue) {
            state.imagePath = state.imagePath.replace('-IMAGEPATH-', newValue.environment);
        },
        setLanguage(state, newValue) {
            state.language = newValue.language;
            if(newValue.language == 'FR') state.country = newValue.language;
        },
        setCountry(state, newValue) {
            state.country = newValue;
        },
        setUpdateView(state, date) {
            state.updateView = date;
        },
        setContractSteps(state, newContractSteps) {
            state.contractSteps = { ...state.contractSteps, ...newContractSteps };
        },
        clearContractSteps(state) {
            state.contractSteps = {};
        },
        stepsTimeStamp(state) {
            const newTime = new Date().getTime();

            return;
            if(state.contractStepsTimeStamp===0) {
                state.contractStepsTimeStamp = newTime;
                return;
            }

            const difTime = Math.abs(newTime - state.contractStepsTimeStamp);
            const resTime = Math.floor( difTime / 1000);
            if(resTime < 300) {
                state.contractStepsTimeStamp = newTime;
            } else {
                //resetting Steps
                state.contractSteps = {
                    step01: {
                        companyselection: 'default',
                        companyselectionText: '-',
                        companyannualsales: 0,
                        yearlyCost: '',
                        companyfrom: '',
                        companyto: '',
                        pass: false,
                        stepsTimeOut: true
                    }
                },
                state.contractStepsTimeStamp = newTime;
            }
        },
        updateSelectedStepState(state, newValue) {
            state.selectedStep = newValue;
        },
        setCookiesStatus(state, value) {
            state.cookies.status = value;
            console.log(state);
        }
    },
    actions: {
        updateEnvironment({ commit }, newValue) {
            commit('setEnvironment', newValue);
            commit('setImagePath', newValue);
            commit('setLanguage', newValue);
            
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            const hours = String(currentDate.getHours()).padStart(2, '0');
            const minutes = String(currentDate.getMinutes()).padStart(2, '0');
            const formattedDate = (this.state.currentEnvironment == 'test')? `${year}${month}${day}-${hours}:${minutes}`:`${year}${month}${day}`;

            commit('setUpdateView', formattedDate);
            console.log(this.state);
        },
        updateCountry({ commit }, newValue) {
            commit('setCountry', newValue);
        },
        updateContractSteps({ commit }, newContractSteps ) {
            commit('setContractSteps', newContractSteps);
            commit('stepsTimeStamp');
        },
        clearAllSteps({ commit }) {
            commit('clearContractSteps');
        },
        reviewStepTimeStamp({ commit }) {
            commit('stepsTimestamp');
        },
        updateSelectedStep({ commit }, newValue) {
            commit('updateSelectedStepState', newValue);
        },
        updCookiesStatus({ commit }, status) {
            commit('setCookiesStatus', status);
        }
    }
});