<template></template>
<script>
export default {
    created() {
        const language = {
            title: "COGITANDA Cyber Gestion de Crise", 
            crisistitle: "PILOTAGE DE LA CELLULE DE CRISE", 
            crisislable: "Nous prenons en charge la gestion à 360° de toutes les mesures de réaction à la crise, de l'analyse au rétablissement de l'activité en passant par la gestion des dommages.", 
            cybertitle: "ÉQUIPE PROFESSIONNELLE D’EXPERTS INFORMATIQUES", 
            cyberlabel: "Pour une réponse professionnelle optimale à l'incident cyber dans votre entreprise, nous mettons en place une équipe de spécialistes informatiques issus de notre réseau d'experts.", 
            protecttitle: "PROTECTION DES DONNEES ET APPPLICATION DE LA LOI", 
            protectlabel: "Des conseils sur les questions de protection des données, la communication avec l'autorité de surveillance de la protection des données (CNIL), la police ou la gendarmerie et la mise à disposition d'avocats spécialisés sont également couvertes par notre réseau d'experts."
        };
        this.$emit('passLanguage', language)
    }
}
</script>