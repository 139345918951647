<template></template>
<script>
export default {
    created() {
        const language = {
            title: "Cyber Aide d’Urgence", 
            hotlinetitle: "365/24/7 HOTLINE", 
            hotlinelabel: "Notre hotline, composée d’experts chevronnés en cyber et en informatique, est à votre disposition 24 heures sur 24 et ce, 365 jours par an.", 
            analysetitle: "ANALYSE & MESURES IMMÉDIATES", 
            analyselabel: "Au plus tard 2 heures après le signalement de l'urgence, COGITANDA entame une analyse concrète ainsi que la mise en œuvre de mesures immédiates.", 
            networktitle: "INTERVENTION DE NOTRE RÉSEAU DE SPÉCIALISTES", 
            networklabel: "L'intervention de spécialistes cyber de notre réseau est incluse dans l'aide immédiate de COGITANDA<sup><small>®</small></sup> Cyber Aide d’Urgence pendant les 48 premières heures, dans la limite de 7.500€.", 
            accesstitle: "ACCÈS AU RÉSEAU D’EXPERTS COGITANDA<sup><small>®</small></sup>",
            accesslabel: "En souscrivant au module COGITANDA<sup><small>®</small></sup> Cyber Gestion de Crise, un grand nombre de spécialistes sont disponibles sur demande, par exemple des experts en criminalité informatique, des spécialistes de la sécurité informatique, des juristes, des gestionnaires de crise ainsi que des experts en communication de crise et en négociation dans des situations d'extorsion.", 
            taxlabel: "",
            price: "Des prix"
        };
        this.$emit('passLanguage', language)
    }
}
</script>