<template>
    <div class="p-yscroll-content" id="contractinformation">
        <div class="frame">
            <div class="title">Präambel</div>
            <p>Die COGITANDA® betreut Unternehmen und private Haushalte bei Cyber Vorfällen. Die COGITANDA® bietet diesbezüglich ein Produkt an, das sich in zwei voneinander unabhängige Leistungsteile aufteilt. Der erste Leistungsteil mit dem Namen „COGITANDA® Cyber Sofort Hilfe“ beinhaltet die Bereitstellung einer 24/7-Notfall-Hotline zur Meldung eines möglichen Cyber Vorfalls, die Einleitung von Sofortmaßnahmen und den Einsatz von Cyber Spezialisten aus unserem Netzwerk innerhalb der ersten 48 Stunden bis zu einem Rechnungsbetrag von 7.500 EUR. Dieser Dienstleistungsvertrag regelt diesen ersten Leistungsteil.</p>
            <p>Der zweite Leistungsteil mit dem Namen „COGITANDA® Cyber Krisen Management“ muss vom Kunden in einem separaten Dienstleistungsvertrag beauftragt und vereinbart werden und ist nicht Gegenstand dieses Vertrages.</p>
            <p>Dies vorausgeschickt, schließen die Parteien den folgenden Vertrag: </p>
            <div class="title">§ 1 Vertragsgegenstand</div>

            <ol>
                <li>
                    Der Kunde beauftragt die COGITANDA® mit der Durchführung der folgenden Dienstleistungen:
                    <ol type="a">
                        <li>
                            <span class="subtitle">24/7 Notfall-Hotline</span>
                            <ul class="custom-bullet">
                                <li>Telefonische Erreichbarkeit einer Notfall-Hotline rund um die Uhr</li>
                                <li>Alleinige Nummer für die Notfall-Hotline ist: +49 (0) 221 99575205</li>
                                <li>Abfrage und Aufnahme aller relevanten Informationen</li>
                                <li>Weiterleitung an das COGITANDA®-Experten-Team</li>
                            </ul>
                        </li>
                        <li>
                            <span class="subtitle">Einleitung von Sofortmaßnahmen</span>
                            <ul class="custom-bullet">
                                <li>Gespräch mit COGITANDA®-Experten innerhalb von 120 Minuten</li>
                                <li>Status-Quo-Analyse auf Basis der vom Kunden übermittelten Informationen</li>
                                <li>Initiale Bewertung des Cyber Vorfalls</li>
                                <li>Beratung zu möglichen Handlungsoptionen</li>
                            </ul>
                        </li>
                        <li>
                            <span class="subtitle">Einsatz Cyber-Spezialisten in den ersten 48h bis 7.500 EUR</span>
                            <ul class="custom-bullet">
                                <li>Krisenstab: Einsetzung und Leitung</li>
                                <li>Schadenmanagement und Krisenkommunikation</li>
                                <li>IT-Forensik: Spurensuche, Analyse, Bewertung </li>
                                <li>IT-Instandsetzung: Planung, Initiierung, Umsetzung</li>
                                <li>Datenschutzbewertung</li>
                            </ul>
                        </li>
                    </ol>
                </li>
                <li>
                    Der Kunde hat die Option, im Anschluss den Einsatz des hoch spezialisierten Experten Netzwerkes und der Kapazität der COGITANDA® im Rahmen des COGITANDA® Cyber Krisen Managements separat zu beauftragen.
                </li>
                <li>
                    Die COGITANDA® erbringt die Dienstleistungen im Rahmen eines Dienstvertrages gemäß §§ 611 ff. BGB. Die COGITANDA® verpflichtet sich, die Dienstleistungen nach allgemein anerkanntem Stand der Technik und unter Beachtung der einschlägigen Gesetze auszuführen. 
                </li>
                <li>
                    Die COGITANDA® schuldet ausdrücklich keinen Erfolg der Leistung und übernimmt bzw. gewährt keine Garantie in Bezug auf die vertraglichen und/oder sonstigen Leistungen
                </li>
            </ol>

            <div class="title">§ 2 Dienstleistungspartner</div>
            <ol>
                <li>
                    Die COGITANDA® ist berechtigt, Dritte („Dienstleistungspartner“) zur Erbringung der Dienstleistungen einzusetzen. 
                </li>
                <li>
                    Die COGITANDA® hat das Wahlrecht, aus dem eigenen Netzwerk der Dienstleistungspartner, die für den Fall geeigneten Dienstleister auszuwählen. Der Kunde hat keinen Anspruch auf einen speziellen Dienstleister.
                </li>
            </ol>

            <div class="title">§ 3 Vertragsdauer, Kündigung und auflösende Bedingung</div>
            <ol>
                <li>
                    Dieser Vertrag wird auf die befristete Dauer von einem Jahr geschlossen. Danach verlängert sich der Vertrag automatisch um ein weiteres Jahr, wenn er nicht mit einer Frist von drei Monaten vor Ablauf gekündigt wird.
                </li>
                <li>
                    Das Recht zur Kündigung aus wichtigem Grund ohne Einhaltung einer Frist bleibt unberührt. Ist der wichtige Grund in dem Verhalten einer der Parteien begründet, ist eine außerordentliche Kündigung in der Regel erst zulässig, wenn die andere Partei zuvor unter Setzung einer angemessenen Frist, die nicht länger als 14 Tage zu sein braucht, erfolglos aufgefordert hat, das Verhalten zu ändern oder die hierdurch verursachten Folgen rückgängig zu machen. Das Recht zur Geltendmachung weiterer oder anderer Rechte, insbesondere auf Schadensersatz, bleibt unberührt. 
                </li>
                <li>
                    Der Vertrag endet, wenn der Kunde einen Cyber Versicherungsvertrag bei einem COGITANDA®- Gruppen-Unternehmen abschließt (auflösende Bedingung). In diesem Fall wird die Gebühr für diesen Vertrag ab Beginndatum des Cyber-Versicherungsvertrages p.r.t. zurückerstattet.
                </li>
                <li>
                    Eine Kündigung oder Vertragsauflösung aus wichtigem Grund bedarf der Schriftform.
                </li>
            </ol>

            <div class="title">§ 4 Weisungsfreiheit</div>
            <p>
                Die COGITANDA® unterliegt bei der Erfüllung des Vertrages und bei der Durchführung der von ihr übernommenen Dienstleistungen hinsichtlich Zeiteinteilung und Gestaltung des Tätigkeitsablaufes keinen Weisungen des Kunden.    
            </p>

            <div class="title">§ 5 Gebühren</div>
            <ol>
                <li>
                    Die COGITANDA® erhält für ihre Dienstleistungen während der Vertragsdauer eine pauschale Gebühr, welche sich aus dem in <b>Anlage 1</b> dargestellten Gebührenmodell ergibt. Bei einer Verlängerung der Vertragsdauer fällt die Gebühr für jedes weitere Jahr wieder an.
                </li>
                <li>
                    Der Betrag ist ohne Abzug zahlbar innerhalb von 2 Wochen nach Vertragsschluss. Gegenüber Forderungen der COGITANDA® kann der Kunde nur mit unbestrittenen oder rechtskräftig festgestellten Forderungen aufrechnen; dies gilt nicht für Gegenansprüche aus demselben Vertragsverhältnis.
                </li>
                <li>
                    Es besteht keine Verpflichtung der COGITANDA® und auch kein Anspruch des Kunden, bei vorzeitiger Beendigung des Vertrages eine einmal gezahlte Gebühr ganz oder anteilig zurückzuerstatten. Eine Ausnahme stellt der Abschluss einer Cyber Versicherung bei einem COGITANDA®-Gruppenunternehmen dar. In diesem Fall wird die gezahlte Gebühr anteilig p.r.t. zurückerstattet.
                </li>
            </ol>

            <div class="title">§ 6 Verschwiegenheitspflicht</div>
            <p>
                Die Parteien verpflichten sich, ihre Mitarbeiter und ihre Dienstleistungspartner, Stillschweigen über alle ihnen im Rahmen dieses Vertrages zur Verfügung gestellten vertraulichen Informationen zu bewahren, insbesondere über Betriebs- und Geschäftsgeheimnisse der anderen Partei. Vertraulich ist eine Information, wenn sie explizit so gekennzeichnet ist oder sich ihre Vertraulichkeit aus den jeweiligen Umständen ergibt. Diese Vertraulichkeitsverpflichtung besteht für die Dauer von 1 Jahr nach Ende dieses Vertrages fort.
            </p>

            <div class="title">§ 7 Beschränkung und Ausschluss der Haftung der COGITANDA®</div>
            <ol>
                <li>
                    Die COGITANDA® haftet im Hinblick auf Schäden aus ihren Dienstleistungen nur für Vorsatz und grobe Fahrlässigkeit der von ihr mit der Durchführung dieser Dienstleistungen betrauten Mitarbeiter oder Dienstleistungspartner. Bei einfacher Fahrlässigkeit ist die Haftung ausgeschlossen, es sei denn, es handelt sich dabei um eine den Vertragszweck gefährdende Verletzung wesentlicher Pflichten. 
                </li>
                <li>
                    Außer in Fällen des Vorsatzes ist die Haftung der COGITANDA® je Einzelfall auf 250.000 EUR beschränkt. 
                </li>
                <li>
                    Für mittelbare Schäden und Folgeschäden ist die Haftung der COGITANDA® ausgeschlossen. Insbesondere haftet die COGITANDA® nicht für entgangenen Gewinn, Produktionsausfall, Betriebsunterbrechung, Verlust oder Veränderung von Informationen oder Daten, insbesondere der Veränderung einer Datenstruktur. 
                </li>
                <li>
                    Sofern der Vertrag die Wiederherstellung von Daten beinhaltet, haftet die COGITANDA® für den Verlust von Daten und/oder Programmen insoweit nicht, als der Schaden darauf beruht, dass es der Kunde unterlassen hat, Datensicherungen durchzuführen und dadurch sicherzustellen, dass verloren gegangene Daten mit vertretbarem Aufwand wiederhergestellt werden können. 
                </li>
                <li>
                    Die vorgenannten Haftungsausschlüsse und -beschränkungen gelten nicht im Fall der Verletzung des Lebens, des Körpers oder der Gesundheit oder im Fall der Übernahme einer Garantie seitens der COGITANDA®. 
                </li>
            </ol>

            <div class="title">§ 8 Höhere Gewalt</div>
            <p>
                Höhere Gewalt befreit die Parteien für die Dauer der Störung und im Umfang ihrer Wirkung von den Leistungspflichten. Höhere Gewalt ist jedes außerhalb der Kontrolle der jeweiligen Partei liegende Ereignis, durch das sie ganz oder teilweise an der Erfüllung ihrer Verpflichtungen gehindert wird.
            </p>

            <div class="title">§ 9 Schlussbestimmungen</div>
            <ol>
                <li>
                    Es gilt das Recht der Bundesrepublik Deutschland. 
                </li>
                <li>
                    Gerichtsstand für alle Streitigkeiten aus diesem Vertrag ist Bonn. 
                </li>
                <li>
                    Änderungen oder Ergänzungen dieses Vertrages bedürfen zu ihrer Wirksamkeit der Schriftform. Dies gilt auch für die Aufhebung dieses Schriftformerfordernisses.
                </li>
                <li>
                    Sollten einzelne Bestimmungen dieses Vertrages unwirksam sein oder werden, wird dadurch die Wirksamkeit der übrigen Vereinbarungen nicht berührt. Die Vertragsparteien haben an der Stelle der unwirksamen Bestimmung eine Regelung zu treffen, um den beabsichtigten Erfolg so weit wie möglich zu erreichen. Entsprechendes gilt für die Ausführung von Vertragslücken. 
                </li>
            </ol>

            <div class="turkishframe">
                <div class="row">
                    <div class="col50">
                        <h1>Anlage 1</h1>
                    </div>
                    <div class="col50">
                        <h2 class="float-right">GEBÜHRENMODELL</h2>
                    </div>
                    <div class="col100 no-padding-margin">
                        <small class="float-right">Stand: 16.06.2023</small>
                    </div>
                </div>
            </div>
            <contentPrices/>
        </div>
    </div>
</template>

<script>

export default {
    components: {    
        'contentPrices': require('@/components/contents/prices/content.vue').default
    },
}

</script>