<template>
  <div id="teaser" ref="teaser" :style="'height:'+this.teaserHeight + 'px'">
    <component :is="selectedLanguage" @passLanguage="setLanguage" />
    <div class="board" :class="teaserClass"></div>
    <div class="title">
      <h1>COGITANDA<sup><small>®</small></sup> <br>{{ locallanguage.title }}</h1>
      <h2>{{ locallanguage.subtitle }}</h2>
      <contractform/>
    </div>
    <div class="clear">&nbsp;</div>
  </div>
</template>
  
<style src="../../../components/parts/teaser/style.css"></style>
    
<script>

import { ref, onMounted, onUnmounted, computed } from 'vue'; 
import { mapState } from 'vuex'; 

export default { 
  data() {
    return {
      locallanguage: {
        title: '', subtitle: ''
      },
    }
  },
  setup() {
    const windowHeight = ref(window.innerHeight);
    const windowWidth = ref(window.innerWidth);

    const updateHeight = () => {
      windowHeight.value = window.innerHeight;
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener('resize', updateHeight);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateHeight);
    });

    const teaserHeight = computed(() => {
      let tempHeight = (windowWidth.value < 650)? windowHeight.value * 0.65: windowHeight.value * 0.75;
      return (tempHeight > 900)? 900: tempHeight;
    });
    const teaserClass = computed(() => {
      return (windowWidth.value < 650)? 'teaserMobile': 'teaserDesktop';
    });

    return {
      teaserHeight,
      teaserClass
    };
  },
  methods: {
    isMobile() {
      if(screen.width <= 850) {
          return true;
      }
      else {
          return false;
      }
    },
    setLanguage(locallanguage) {
      this.locallanguage = locallanguage
    }
  },
  computed: {
      ...mapState(['imagePath',  'updateView', 'language']),
      selectedLanguage() {
        return this.language+'component';
      },
      imgHeightSet() {
          const imgHeight = (window.innerHeight - 300);
          return {
              height: `${imgHeight}px`
          };
      }
  },
  components: {
      'contractform': require('@/components/tools/contractform/content.vue').default,
      'DEcomponent': require('@/components/parts/teaser/DE.vue').default,
      'FRcomponent': require('@/components/parts/teaser/FR.vue').default
  }
}
</script>