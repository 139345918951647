<template></template>
<script>
export default {
    created() {
        const language = {
            title: 'UNSER WERKZEUGKASTEN FÜR DAS CYBER KRISEN MANAGEMENT', 
            image_desktop: 'crisisdept/crisistab_desktop.webp',
            image_mobile: 'crisisdept/crisistab_mobile.webp'
        };
        this.$emit('passLanguage', language)
    }
}
</script>