<template></template>
<script>
export default {
    created() {
        const language = {
            aboutus: "ÜBER UNS",
            contact: "KONTAKT",
            cyberfacts: "CYBER FACTS",
            cyberLogoTitle: "COGITANDA® Cyber Sofort Hilfe",
            cogitandaConnect: "Cyber-Versicherung"
        };
        this.$emit('passLanguage', language)
    }
}
</script>