<template>
  <footer class="bkg_turkish">
    <div class="content" :class="(this.language=='FR')? 'FR':''" align="center">
        <ul>
            <li>
              <PopupTool contentType="imp"/>
            </li>
            <li class="holder">|</li>
            <li>
              <PopupTool contentType="dat"/>
            </li>
            <li class="holder">|</li>
            <li>
              <PopupTool contentType="dge"/>
            </li>
        </ul>
    </div>
  </footer>
</template>

<style src="../../../components/parts/footer/style.css"></style>
    
<script>

import { mapState } from 'vuex'; 

export default {
  computed: {
    ...mapState(['imagePath',  'updateView', 'language']),
  },
  components: {    
    'PopupTool': require('@/components/tools/popup/component.vue').default
  }
}
</script>