<template>
    <div class="row">
        <div class="col100 tab-title">
            ENTREPRISE
        </div>
        <div class="col50 no-padding-margin">
            <div class="tab-subtitle">CHIFFRE D’AFFAIRES ANNUEL JUSQU’À</div>
            <div class="tab-content">250.000 euros</div>
            <div class="tab-content">1 millions d'euros</div>
            <div class="tab-content">5 millions d'euros</div>
            <div class="tab-content">50 millions d'euros</div>
            <div class="tab-content">250 millions d'euros</div>
            <div class="tab-content">au-delà 250 millions d'euros</div>
        </div>
        <div class="col50 no-padding-margin">
            <div class="tab-subtitle">COTISATION ANNUELLE NETTE</div>
            <div class="tab-content">49 euros</div>
            <div class="tab-content">99 euros</div>
            <div class="tab-content">199 euros</div>
            <div class="tab-content">399 euros</div>
            <div class="tab-content">799 euros</div>
            <div class="tab-content">sur demande</div>
        </div>
    </div>
    <div class="row">
        <div class="col100 tab-title">
            BANQUE
        </div>
        <div class="col50 no-padding-margin">
            <div class="tab-subtitle">CHIFFRE D’AFFAIRES ANNUEL JUSQU’À</div>
            <div class="tab-content">200 millions d'euros</div>
            <div class="tab-content">350 millions d'euros</div>
            <div class="tab-content">500 millions d'euros</div>
            <div class="tab-content">2,5 milliards d'euros</div>
            <div class="tab-content">au-delà  2,5 milliards d'euros</div>
        </div>
        <div class="col50 no-padding-margin">
            <div class="tab-subtitle">COTISATION ANNUELLE NETTE</div>
            <div class="tab-content">999 euros</div>
            <div class="tab-content">1.999 euros</div>
            <div class="tab-content">2.499 euros</div>
            <div class="tab-content">4.999 euros</div>
            <div class="tab-content">sur demande</div>
        </div>
    </div>
    <div class="row">
        <div class="col100 tab-title">
            MUNICIPALITÉ
        </div>
        <div class="col50 no-padding-margin">
            <div class="tab-subtitle">CHIFFRE D’AFFAIRES ANNUEL JUSQU’À</div>
            <div class="tab-content">100 millions d'euros</div>
            <div class="tab-content">150 millions d'euros</div>
            <div class="tab-content">200 millions d'euros</div>
            <div class="tab-content">300 millions d'euros</div>
            <div class="tab-content">au-delà  300 millions d'euros</div>
        </div>
        <div class="col50 no-padding-margin">
            <div class="tab-subtitle">COTISATION ANNUELLE NETTE</div>
            <div class="tab-content">999 euros</div>
            <div class="tab-content">1.999 euros</div>
            <div class="tab-content">2.999 euros</div>
            <div class="tab-content">4.999 euros</div>
            <div class="tab-content">sur demande</div>
        </div>
    </div>
</template>

<script>

export default {
}

</script>