<script setup>
import VueDatePicker from '@vuepic/vue-datepicker';
</script>
<template>
    <div class="inner lightgraybkg">
        <div class="d-floatleft">
            <div class="d-content form" id="step04">
                <div class="title">
                    Mode de paiement
                </div>

                <div class="p-width100">
                    <div class="divSelect">
                        <div class="itemSelect p-inputtext p-component" 
                            :class="{ 'itemInactive': stepData.paymentmethod!=='Prélèvement automatique' }" 
                            @click="paymentMethod('Prélèvement automatique')">
                            <img class="check" :src="imagePath + 'contractform/check.'+((stepData.paymentmethod=='Prélèvement automatique')? 'active':'inactive')+'.png?'+updateView"/> 
                            Prélèvement automatique
                        </div>
                        <div class="itemSelect p-inputtext p-component" 
                            :class="{ 'itemInactive': stepData.paymentmethod!=='Facture' }"
                            @click="paymentMethod('Facture')">
                            <img class="check" :src="imagePath + 'contractform/check.'+((stepData.paymentmethod=='Facture')? 'active':'inactive')+'.png?'+updateView"/> 
                            Facture
                        </div>
                    </div>
                </div>

                <div class="p-width100" v-if="stepData.paymentmethod==='Prélèvement automatique'">
                    <input 
                    v-model="stepData.paymentiban"
                    id="paymentiban"  
                    type="text"
                    class="p-inputtext p-component reqsubmit"
                    />
                    <span>IBAN *</span>
                </div>
                <div class="p-width100" v-if="stepData.paymentmethod==='Prélèvement automatique'">
                    <input 
                    v-model="stepData.paymentbic"
                    id="paymentbic"  
                    type="text"
                    class="p-inputtext p-component reqsubmit"
                    />
                    <span>BIC *</span>
                </div>

                <div>&nbsp;</div>
                <div class="title">Vendu par</div>

                <div class="p-width100">
                    <div class="divSelect">
                        <div class="itemSelect itemSelectFull p-inputtext p-component" 
                            :class="{ '': stepData.partnermediator, 'itemInactive': !stepData.partnermediator }" 
                            @click="partnerMediator(true)">
                            <img class="check" :src="imagePath + 'contractform/check.'+((stepData.partnermediator===true)? 'active':'inactive')+'.png?'+updateView"/> 
                            Intérmediaire du distributeur de COGITANDA®
                        </div>
                    </div>
                </div>

                <div class="p-width100" v-if="stepData.partnermediator">
                    <input 
                    v-model="stepData.partnername"
                    id="partnername"  
                    type="text"
                    placeholder="Nom du distributeur COGITANDA® *"
                    class="p-inputtext p-component reqsubmit"
                    />
                </div>
                <div class="p-width100" v-if="stepData.partnermediator">
                    <input
                    v-model="stepData.partnernumber"
                    id="partnernumber"  
                    type="text"
                    placeholder="Numéro de partenaire COGITANDA® *"
                    class="p-inputtext p-component reqsubmit"
                    />
                </div>
                <div class="d-floatclear" v-if="stepData.partnermediator"></div>
                <div class="p-width100" v-if="stepData.partnermediator">
                    <VueDatePicker 
                        v-model="stepData.partnerdate" 
                        name="partnerdate" 
                        id="partnerdate" 
                        locale="de" 
                        teleport-center 
                        cancelText="✕" 
                        selectText="✔" 
                        :format="format"
                        />
                    <span>Date *</span>
                </div>
                <div class="d-floatclear" v-if="stepData.partnermediator"></div>
                <div class="d-floatclear" v-if="stepData.partnermediator"></div>
                <div class="d-floatclear" v-if="stepData.partnermediator"></div>
                 
                <div class="p-width100">
                    <div class="divSelect">
                        <div class="itemSelect itemSelectFull p-inputtext p-component" 
                            :class="{ '': !stepData.partnermediator, 'itemInactive': stepData.partnermediator }" 
                            @click="partnerMediator(false)">
                            <img class="check" :src="imagePath + 'contractform/check.'+((stepData.partnermediator===false)? 'active':'inactive')+'.png?'+updateView"/> 
                            Pas d'intermédiation pas les distributeurs COGITANDA®.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-floatright">
            <div class="d-content form" id="formBoard">
                <div class="tab-error" v-if="stepData.stepPass==false">
                    Veuillez remplir tous les champs obligatoires pour continuer.
                </div>
            </div>
        </div>
        <div class="d-floatclear form" style="padding: 0!important; height: 1px!important">&nbsp;</div>
          
    </div>
</template>

<style src="../../../../components/tools/contractform/FR/step04.css"></style>

<script>

import { mapState, mapActions } from 'vuex'; 

export default {
    data() {
        return {
            stepName: 'step04',
            stepData: {
                paymentmethod: 'Prélèvement automatique',
                paymentiban: '',
                paymentbic: '',
                partnermediator: true,
                partnername: '',
                partnername: '',
                partnerdate: '',
            }
        }
    },
    computed: {
        ...mapState(['imagePath', 'updateView', 'country', 'contractSteps']),
    },
    watch: {
        'contractSteps': {
            handler(newdata) {
                this.stepData = newdata.step04;
            }
        }
    },
    methods: {
        ...mapActions(['updateContractSteps']),
        changeContractSteps(newContractSteps) {
            this.$store.dispatch('updateContractSteps', newContractSteps);
        },
        paymentMethod(option) {
            this.stepData.paymentmethod = option;
        },
        partnerMediator(option) {
            this.stepData.partnermediator = option;
        },
        format(date) {
            const day = (date.getDate() < 10) ? '0' + date.getDate() : date.getDate();
            const month = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
            const year = date.getFullYear();

            return `${day}.${month}.${year}`;
        },
    }, 
    mounted() {
        if(!this.contractSteps.hasOwnProperty(this.stepName)){

            this.stepData = {...this.stepData};
            this.changeContractSteps({step04: this.stepData});

        } else {
            this.stepData = this.contractSteps.step04;
        }
    }
}
</script>