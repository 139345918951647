<template>
    <FRcontent  v-if="this.language=='FR'"/>
    <DEcontent  v-else/>
</template>

<script>

import { mapState } from 'vuex'; 

export default {
  computed: {
    ...mapState(['language']),
  },
  components: {
    'DEcontent': require('@/components/contents/dataprotectionbusiness/DE.vue').default,
    'FRcontent': require('@/components/contents/dataprotectionbusiness/FR.vue').default
  }
}

</script>