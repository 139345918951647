<template>
    <div class="inner lightgraybkg">
        <div class="d-floatleft">
            <div class="d-content form" id="step02">
                <div class="title">
                    Données de l'entreprise / adresse de facturation
                </div>
                <div class="p-width100">
                    <input 
                        v-model="stepData.companyname"
                        id="companyname"
                        input="text" 
                        class="p-inputtext p-component reqsubmit"
                        />
                    <span>Nom de l'entreprise *</span>
                </div>
                <div class="p-width100" >
                    <input 
                        v-model="stepData.companystreetnumber"
                        id="companystreetnumber" 
                        type="text" 
                        class="p-inputtext p-component reqsubmit"
                        />
                    <span>Rue / n° *</span>
                </div>
                <div class="p-width100">
                    <input 
                        v-model="stepData.companypostcity"
                        id="companypostcity"  
                        type="text" 
                        class="p-inputtext p-component reqsubmit"
                        />
                    <span>Code postal / Lieu *</span>
                </div>

                <div class="p-width100">
                    <div class="p-inputtext p-component">
                       FRANCE
                    </div>
                    <span>Pays (siège social) *</span>
                </div>

                <div class="p-width50L">
                    <input 
                        v-model="stepData.companylegalform"
                        id="companylegalform"  
                        class="p-inputtext p-component reqsubmit"
                        type="text"
                        /><br/>
                    <span>Forme juridique *</span>
                </div>
                
                <div class="p-width50R">
                    <input 
                        v-model="stepData.companyemployees"
                        id="companyemployees"  
                        class="p-inputtext p-component reqsubmit"
                        type="number"
                        /><br/>
                    <span>Nombre d'employés *</span>
                </div>
                <div class="d-floatclear"></div>

                <div class="p-width100" >
                    <input 
                        v-model="stepData.companyregister"
                        id="companyregister"  
                        type="text"
                        class="p-inputtext p-component reqsubmit"
                        />
                        <span>Numéro de RCS *</span>
                </div>
                <div class="p-width100" >
                    <input
                        v-model="stepData.companytax"
                        id="companytax"  
                        type="text"
                        class="p-inputtext p-component reqsubmit"
                        />
                        <span>Numéro de TVA intracommunautaire *</span>
                </div>
            </div>
        </div>
        <div class="d-floatright">
            <div class="d-content form" id="formBoard">
                <div class="tab-error" v-if="stepData.stepPass==false">
                    Veuillez remplir tous les champs obligatoires pour continuer.
                </div>
            </div>
        </div>
        <div class="d-floatclear form" style="padding: 0!important; height: 1px!important">&nbsp;</div>
    </div>  
</template>
  
<style src="../../../../components/tools/contractform/FR/step02.css"></style>
  
<script>

import { mapState, mapActions } from 'vuex'; 

export default {
    data() {
        return {
            stepName: 'step02',
            stepData: {
                companyname: '',
                companystreetnumber: '',
                companypostcity: '',
                companycountry: '',
                companylegalform: '',
                companyemployees: 0,
                companyregister: '',
                companytax: '',
            }
        }
    },
    computed: {
        ...mapState(['imagePath', 'updateView', 'country', 'contractSteps']),
    },
    watch: {
        'contractSteps': {
            handler(newdata) {
                this.stepData = newdata.step02;
            }
        }
    },
    methods: {
        ...mapActions(['updateContractSteps']),
        changeContractSteps(newContractSteps) {
            this.$store.dispatch('updateContractSteps', newContractSteps);
        },
        changeCountry(country) {
            this.$store.dispatch('updateCountry', country);
        },
    }, 
    mounted() {
        if(!this.contractSteps.hasOwnProperty('step02')){

            this.stepData = {...this.stepData, companycountry: this.country};
            this.changeContractSteps({step02: this.stepData});

        } else {
            this.stepData = this.contractSteps.step02;
        }
    }
}
</script>