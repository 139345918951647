<template>
    <ATcontent v-if="country=='AT'"/>
    <DEcontent v-else/>
  </template>
  
  <style src="../../../components/contents/contract/style.css"></style>
  
  <script>
  
  import { mapState } from 'vuex'; 
  
  export default {
  computed: {
    ...mapState(['language', 'country']),
  },
  components: {
    'DEcontent': require('@/components/contents/contract/DE_DE.vue').default,
    'ATcontent': require('@/components/contents/contract/DE_AT.vue').default
  }
  }
  
  </script>