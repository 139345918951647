<template>
    <div :class="{ destroyview:isFormSucceeded === false }">
        <div id="step06">
            <div class="inner form">
                <div class="frame">
                    <p>
                        Nous sommes très heureux que notre offre vous ait convaincu. La Cyber Aide d'Urgence est à votre disposition dès maintenant
                    </p>
                    <p>
                        Vous trouverez ici <a @click="downloadPDF">le contrat de service.</a>
                    </p>
                    <p :class="{ 'timeError':timestampEnd }">
                        Pour des raisons de sécurité des données, le document contractuel est disponible en téléchargement pendant 5 minutes.
                    </p>
                    <p>
                        Vous pouvez joindre notre équipe en cas d'urgence cyber 24h/24au <br/>
                        <a href="callto:+33186654526“">+33 186 65 45 26</a>
                    </p>
                    
                </div>
            </div>
        </div>
    </div>
</template>
  
<style src="../../../../components/tools/contractform/FR/step06.css"></style>
  
<script>
import { mapState } from 'vuex'; 
export default {
    data() {
        return {
            downloaded: false,
            timestampBegin: null,
            timestampEnd: false
        }
    },
    computed: {
        ...mapState(['imagePath', 'updateView', 'country', 'contractSteps']),
    },
    methods: {
        downloadPDF() {

            const timeNow = new Date().getTime();
            const diffTime = Math.abs(timeNow - this.timestampBegin);
            const calcTime = Math.floor( diffTime / 1000);
            if(calcTime >= 300) { 
                console.log(calcTime, this.timestampBegin);
                this.timestampEnd = true;           
                return;
            }

            if(this.downloaded===true) return;
            
            const linkSource = "data:application/pdf;base64," + this.contractSteps.response.data;
            const downloadLink = document.createElement('a');
            downloadLink.href = linkSource;
            downloadLink.download = 'COGITANDA_le_contrat_de_service.pdf';

            document.body.appendChild(downloadLink);
            downloadLink.click();
            this.downloaded=true;
            
        },
        registerTimeStamp() {
            this.timestampBegin = new Date().getTime();
        }
    },
    mounted() {
        this.registerTimeStamp();
    }
}
</script>