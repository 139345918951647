<template>
    <div>
        <div class="row">
            <div class="col100 tab-title">
                UNTERNEHMEN 
            </div>
            <div class="col50 no-padding-margin">
                <div class="tab-subtitle">JAHRESUMSATZ BIS ZU</div>
                <div class="tab-content">250.000 Euro</div>
                <div class="tab-content">1 Mio. Euro</div>
                <div class="tab-content">5 Mio. Euro</div>
                <div class="tab-content">50 Mio. Euro</div>
                <div class="tab-content">250 Mio. Euro</div>
                <div class="tab-content">über 250 Mio. Euro</div>
            </div>
            <div class="col50 no-padding-margin">
                <div class="tab-subtitle">NETTOJAHRESGEBÜHR <span>(zzgl. MwSt.)</span></div>
                <div class="tab-content">49 Euro</div>
                <div class="tab-content">99 Euro</div>
                <div class="tab-content">199 Euro</div>
                <div class="tab-content">399 Euro</div>
                <div class="tab-content">799 Euro</div>
                <div class="tab-content">auf Anfrage</div>
            </div>
        </div>
        <div class="row">
            <div class="col100 tab-title">
                BANKEN
            </div>
            <div class="col50 no-padding-margin">
                <div class="tab-subtitle">BILANZSUMME BIS ZU</div>
                <div class="tab-content">200 Mio. Euro</div>
                <div class="tab-content">350 Mio. Euro</div>
                <div class="tab-content">500 Mio. Euro</div>
                <div class="tab-content">2,5 Mrd. Euro</div>
                <div class="tab-content">über 2,5 Mrd. Euro</div>
            </div>
            <div class="col50 no-padding-margin">
                <div class="tab-subtitle">NETTOJAHRESGEBÜHR <span>(zzgl. MwSt.)</span></div>
                <div class="tab-content">999 Euro</div>
                <div class="tab-content">1.999 Euro</div>
                <div class="tab-content">2.499 Euro</div>
                <div class="tab-content">4.999 Euro</div>
                <div class="tab-content">auf Anfrage</div>
            </div>
        </div>
        <div class="row">
            <div class="col100 tab-title">
                KRANKENKASSEN
            </div>
            <div class="col50 no-padding-margin">
                <div class="tab-subtitle">ANZAHL MITGLIEDER BIS ZU</div>
                <div class="tab-content">450.000</div>
                <div class="tab-content">750.000</div>
                <div class="tab-content">1 Mio.</div>
                <div class="tab-content">2,5 Mio.</div>
                <div class="tab-content">über 2,5 Mio.</div>
            </div>
            <div class="col50 no-padding-margin">
                <div class="tab-subtitle">NETTOJAHRESGEBÜHR <span>(zzgl. MwSt.)</span></div>
                <div class="tab-content">999 Euro</div>
                <div class="tab-content">1.999 Euro</div>
                <div class="tab-content">2.999 Euro</div>
                <div class="tab-content">4.999 Euro</div>
                <div class="tab-content">auf Anfrage</div>
            </div>
        </div>
        <div class="row">
            <div  v-if="country=='AT'" class="col100 tab-title">
                GEMEINDEN
            </div>
            <div v-else class="col100 tab-title">
                KOMMUNEN
            </div>
            <div class="col50 no-padding-margin">
                <div class="tab-subtitle">HAUSHALTSVOLUMEN BIS ZU</div>
                <div class="tab-content">100 Mio. Euro</div>
                <div class="tab-content">150 Mio. Euro</div>
                <div class="tab-content">200 Mio. Euro</div>
                <div class="tab-content">300 Mio. Euro</div>
                <div class="tab-content">über 300 Mio. Euro</div>
            </div>
            <div class="col50 no-padding-margin">
                <div class="tab-subtitle">NETTOJAHRESGEBÜHR <span>(zzgl. MwSt.)</span></div>
                <div class="tab-content">999 Euro</div>
                <div class="tab-content">1.999 Euro</div>
                <div class="tab-content">2.999 Euro</div>
                <div class="tab-content">4.999 Euro</div>
                <div class="tab-content">auf Anfrage</div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'; 

export default {
    computed: {
        ...mapState(['country']),
    },
}

</script>