<template>
    <div id="think" class="bkg_white">
        <component :is="selectedLanguage" @passLanguage="setLanguage" />
        <div class="cluster01">
            <img :style="isMobile()? imgHeightSet:''" :alt="locallanguage.title1" :src="imagePath + 'think/cluster_pic01'+(isMobile()?'_mobile':'')+'.webp?'+updateView" width="425" height="500"/>
            <div class="info">
                <h3>{{ locallanguage.title1 }}</h3>
                <p>
                    {{ locallanguage.subtitle1 }}
                </p>
                <br>
                
            </div>
            <div class="clear"></div>
        </div>
    
        <div class="cluster02">
            <img :style="isMobile()? imgHeightSet:''" :alt="locallanguage.title2" :src="imagePath + 'think/cluster_pic02'+(isMobile()?'_mobile':'')+'.webp?'+updateView" width="425" height="500"/>
            <div class="info">
                <h3>{{ locallanguage.title2 }}</h3>
                <p>{{ locallanguage.subtitle2 }}</p>
                <br>
                
                <div class="clear"></div>
            </div>
            <div class="clear"></div>
        </div>

        <div class="contact247">
            <img class="icon" alt="24/7" :src="imagePath + 'think/icon_247.png?'+updateView"/>
            <p v-html="locallanguage.label24"></p>
        </div>
    </div>
</template>

<style src="../../../components/parts/think/style.css"></style>
    
<script>

import { mapState } from 'vuex'; 

export default {
    data() {
        return {
            locallanguage: {
                title1:'', subtitle1: '', title2:'', subtitle2:'', label24: ''
            }
        }
    },
    methods: {
      isMobile() {
          if( screen.width <= 850 ) {
              return true;
          }
          else {
              return false;
          }
      },
      setLanguage(locallanguage) {
        this.locallanguage = locallanguage
      }
    },
    computed: {
        ...mapState(['imagePath',  'updateView', 'language']),
        selectedLanguage() {
            return this.language+'component';
        },
        imgHeightSet() {
            const imgHeight = (window.innerHeight - 300);
            return {
                width: `100%`
            };
        }
    },
    components: {
        'DEcomponent': require('@/components/parts/think/DE.vue').default,
        'FRcomponent': require('@/components/parts/think/FR.vue').default
    }
}
</script>