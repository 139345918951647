<template>
    <div :class="{ destroyview:isFormSucceeded === false }">
        <div id="step06">
            <div class="inner form">
                <div class="frame">
                    <p>
                        Es freut uns sehr, dass Sie unser Angebot überzeugt hat. Die Cyber Sofort Hilfe steht Ihnen ab sofort zur Verfügung.
                    </p>
                    <p>
                        Den vereinbarten Dienstleistungsvertrag für Ihre Unterlagen finden Sie 
                        <a @click="downloadPDF">hier.</a>
                    </p>
                    <p :class="{ 'timeError':timestampEnd }">
                        Das Vertragsdokument steht Ihnen aus Datensicherheitsgründen für 5 Minuten zum Download zur Verfügung.
                    </p>
                    <p>
                        Sie erreichen unser Team im Cyber-Notfall rund um die Uhr unter <br/>
                        <a v-if="country=='AT'" href="callto:+431206092330“">+43 (0)1 206092330.</a> 
                        <a v-else href="callto:+4922199575205“">+49 (0) 221 / 99575205.</a>
                    </p>
                    
                </div>
            </div>
        </div>
    </div>
</template>
  
<style src="../../../../components/tools/contractform/DE/step06.css"></style>
  
<script>
import { mapState } from 'vuex'; 
export default {
    data() {
        return {
            downloaded: false,
            timestampBegin: null,
            timestampEnd: false
        }
    },
    computed: {
        ...mapState(['imagePath', 'updateView', 'country', 'contractSteps']),
    },
    methods: {
        downloadPDF() {

            const timeNow = new Date().getTime();
            const diffTime = Math.abs(timeNow - this.timestampBegin);
            const calcTime = Math.floor( diffTime / 1000);
            if(calcTime >= 300) { 
                console.log(calcTime, this.timestampBegin);
                this.timestampEnd = true;           
                return;
            }

            if(this.downloaded===true) return;
            
            const linkSource = "data:application/pdf;base64," + this.contractSteps.response.data;
            const downloadLink = document.createElement('a');
            downloadLink.href = linkSource;
            downloadLink.download = 'COGITANDA_DienstLeistungsVertrag.pdf';

            document.body.appendChild(downloadLink);
            downloadLink.click();
            this.downloaded=true;
            
        },
        registerTimeStamp() {
            this.timestampBegin = new Date().getTime();
        }
    },
    mounted() {
        this.registerTimeStamp();
    }
}
</script>