<template></template>
<script>
export default {
    created() {
        const language = {
            title1: 'VOUS PENSEZ QUE SEULES LES GRANDES ENTREPRISES SONT LA CIBLE DES PIRATES ?', 
            subtitle1: 'La cyber criminalité est une activité lucrative pour les organisations et réseaux criminels ainsi que pour certains gouvernements. Depuis longtemps, des entreprises de toutes tailles sont attaquées de manière ciblée.', 
            title2: 'VOUS PENSEZ QUE VOTRE PRESTATAIRE INFORMATIQUE S’EN OCCUPE DEJA ?', 
            subtitle2: "En cas d'attaque réussie, il faut non seulement coordonner les services techniques, mais aussi l'organisation de l'entreprise, la gestion de crise et les communications interne, externe et aux autorités compétentes.", 
            label24: "Souvent après une cyberattaque, l’activité de l’entreprise est interrompue. Chaque minute compte pour éviter le pire. <br>Avec <b>la Cyber Aide d’Urgence de COGITANDA,<sup><small>®</small></sup></b> vous êtes pleinement préparé - nous sommes joignables 24h/24 7j/7, et vous accompagnons dans les 2 heures après notre premier contact. Nous vous proposons une analyse concrète et des mesures immédiates."

        };
        this.$emit('passLanguage', language)
    }
}
</script>