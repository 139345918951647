<script setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import InputNumber from 'primevue/inputnumber';
</script>


<template>
    <div class="inner lightgraybkg">
        <div class="d-floatleft">
            <div class="d-content form" id="step01">
                <div class="title">
                    Calcul de la cotisation annuelle
                </div>
                    
                <div class="p-width100">
                    <input type="hidden"
                        id="companylocation" value="FR"/>
                </div>

                <div class="p-width100">
                    <select 
                        v-model="stepData.companyselection"  
                        class="p-inputtext p-component"
                        @change="companyselectionOption"
                        >
                            <option value="default">Veuillez sélectonner</option>
                            <option value="Entreprise">Entreprise</option>
                            <option value="Banque">Banque</option>
                            <option value="Municipalité">Municipalité</option>
                    </select>
                    <span>Catégorie de frais *</span>
                </div>
                                
                <div class="p-width100" v-if="stepData.companyselection != 'default'">
                    <InputNumber 
                        v-model="stepData.companyannualsales" 
                        inputId="currency-france" 
                        class="p-component"
                        @input="inputSearchPrices"
                        mode="currency" 
                        currency="EUR" 
                        locale="fr-FR" />
                    <span>{{ stepData.companyselectionText }}</span>
                </div>
                                
                <div class="p-width50L">
                    <VueDatePicker 
                        v-model="stepData.companyfrom" 
                        locale="fr" 
                        teleport-center  
                        cancelText="✕" 
                        selectText="✔" 
                        :format="format"
                    />
                    <span>Exercice du *</span>
                </div>
                <div class="p-width50R">
                    <VueDatePicker 
                        v-model="stepData.companyto" 
                        locale="fr" 
                        teleport-center  
                        cancelText="✕" 
                        selectText="✔" 
                        :format="format"
                    />
                    <span>au *</span>
                </div>
                <div class="d-floatclear"></div>

                <div class="p-width100">
                    <span class="cost" v-if="stepData.yearlyCost!=''">{{ stepData.yearlyCost }} - Votre cotisation annuelle</span>
                </div>
            </div>
        </div>
        <div class="d-floatright">
            <div class="d-content form" id="formBoard">
                <div class="prices">
                    
                    <div class="row" v-if="stepData.companyselection=='Entreprise'">
                        <div class="col100 tab-title tab-border">
                            ENTREPRISE
                        </div>
                        <div class="col50 no-padding-margin">
                            <div class="tab-subtitle tab-border">CHIFFRE D’AFFAIRES ANNUEL JUSQU’À</div>
                            <div class="tab-content tab-border">250.000 euros</div>
                            <div class="tab-content tab-border">1 millions d'euros</div>
                            <div class="tab-content tab-border">5 millions d'euros</div>
                            <div class="tab-content tab-border">50 millions d'euros</div>
                            <div class="tab-content tab-border">250 millions d'euros</div>
                            <div class="tab-content tab-border">au-delà 250 millions d'euros</div>
                        </div>
                        <div class="col50 no-padding-margin">
                            <div class="tab-subtitle tab-border">COTISATION ANNUELLE NETTE</div>
                            <div class="tab-content tab-border">49 euros</div>
                            <div class="tab-content tab-border">99 euros</div>
                            <div class="tab-content tab-border">199 euros</div>
                            <div class="tab-content tab-border">399 euros</div>
                            <div class="tab-content tab-border">799 euros</div>
                            <div class="tab-content tab-border">sur demande</div>
                        </div>
                    </div>
                    <div class="row" v-if="stepData.companyselection=='Banque'">
                        <div class="col100 tab-title tab-border">
                            BANQUE
                        </div>
                        <div class="col50 no-padding-margin">
                            <div class="tab-subtitle tab-border">CHIFFRE D’AFFAIRES ANNUEL JUSQU’À</div>
                            <div class="tab-content tab-border">200 millions d'euros</div>
                            <div class="tab-content tab-border">350 millions d'euros</div>
                            <div class="tab-content tab-border">500 millions d'euros</div>
                            <div class="tab-content tab-border">2,5 milliards d'euros</div>
                            <div class="tab-content tab-border">au-delà  2,5 milliards d'euros</div>
                        </div>
                        <div class="col50 no-padding-margin">
                            <div class="tab-subtitle tab-border">COTISATION ANNUELLE NETTE</div>
                            <div class="tab-content tab-border">999 euros</div>
                            <div class="tab-content tab-border">1.999 euros</div>
                            <div class="tab-content tab-border">2.499 euros</div>
                            <div class="tab-content tab-border">4.999 euros</div>
                            <div class="tab-content tab-border">sur demande</div>
                        </div>
                    </div>
                    <div class="row" v-if="stepData.companyselection=='Municipalité'">
                        <div class="col100 tab-title tab-border">
                            MUNICIPALITÉ
                        </div>
                        <div class="col50 no-padding-margin">
                            <div class="tab-subtitle tab-border">CHIFFRE D’AFFAIRES ANNUEL JUSQU’À</div>
                            <div class="tab-content tab-border">100 millions d'euros</div>
                            <div class="tab-content tab-border">150 millions d'euros</div>
                            <div class="tab-content tab-border">200 millions d'euros</div>
                            <div class="tab-content tab-border">300 millions d'euros</div>
                            <div class="tab-content tab-border">au-delà  300 millions d'euros</div>
                        </div>
                        <div class="col50 no-padding-margin">
                            <div class="tab-subtitle tab-border">COTISATION ANNUELLE NETTE</div>
                            <div class="tab-content tab-border">999 euros</div>
                            <div class="tab-content tab-border">1.999 euros</div>
                            <div class="tab-content tab-border">2.999 euros</div>
                            <div class="tab-content tab-border">4.999 euros</div>
                            <div class="tab-content tab-border">sur demande</div>
                        </div>
                    </div>
                </div>
                <div class="tab-error" v-if="yearlyCostError[0] && yearlyCostError[1] === 1">
                    En raison de la taille de votre entreprise, nous vous prions de faire une demande individuelle via notre <a href="https://csh.testing.cogitanda.io/?lang=FR#formula">formulaire de contact</a>.
                </div>
                <div class="tab-error" v-if="stepData.stepsTimeOut===true">
                    En raison des 5 minutes d'inactivité lors de l'exécution du contrat, un délai d'attente vous a été accordé pour assurer la sécurité de vos données.
                </div>
                <div class="tab-error" v-if="stepData.stepPass==false">
                    Veuillez remplir tous les champs obligatoires pour continuer.
                </div>
            </div>
        </div>
        <div class="d-floatclear form" style="padding: 0!important; height: 1px!important">&nbsp;</div>
    </div>
</template>

<style src="../../../../components/tools/contractform/FR/step01.css"></style>
<script>

import { mapState, mapActions } from 'vuex'; 

export default {
    data() {
        return {
            
            yearlyCostError: [false, 0],
            stepName: 'step01',
            stepData: {
                companyselection: 'default',
                companyselectionText: '-',
                companyannualsales: 0,
                yearlyCost: '',
                companyfrom: '',
                companyto: '',
                pass: false,
                stepsTimeOut: false
            },
            pricesList: {
                category1: {
                    name: 'entreprise',
                    prices: [
                        {range: 250000,     value:  49.00},
                        {range: 1000000,    value:  99.00},
                        {range: 5000000,    value: 199.00},
                        {range: 50000000,   value: 399.00},
                        {range: 250000000,  value: 799.00}
                    ]
                },
                category2: {
                    name: 'banque',
                    prices: [
                        {range: 200000000,    value:  999.00},
                        {range: 350000000,    value:  1999.00},
                        {range: 500000000,    value:  2499.00},
                        {range: 2500000000,   value:  4999.00}
                    ]
                },
                category3: {
                    name: 'municipalité',
                    prices: [
                        {range: 100000000,  value:  999.00},
                        {range: 150000000,  value: 1999.00},
                        {range: 200000000,  value: 2999.00},
                        {range: 300000000,  value: 4999.00}
                    ]
                }
            }
        }
    },
    
    methods: {
        ...mapActions(['updateContractSteps']),
        changeCountry(country) {
            this.$store.dispatch('updateCountry', country);
        },
        changeContractSteps(newContractSteps) {
            this.$store.dispatch('updateContractSteps', newContractSteps);
        },
        companyselectionOption() {
           if(this.stepData.companyselection === 'Banque') {
                this.stepData.companyselectionText = 'total du bilan(€) *';
            } else if(this.stepData.companyselection === 'Municipalité') {
                this.stepData.companyselectionText = 'volume du ménage *';
            } else if(this.stepData.companyselection === 'Entreprise') {
                this.stepData.companyselectionText = "Chiffre d'affaires annuel(€) *";
            } else {
                this.stepData.companyselectionText = '-';
                this.stepData.companyannualsales = '';
                this.stepData.yearlyCost = '';
            }

            //console.log(this.stepData.companyselection);

        },
        format(date) {
            const day = (date.getDate() < 10) ? '0' + date.getDate() : date.getDate();
            const month = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
            const year = date.getFullYear();

            return `${day}.${month}.${year}`;
        },
        inputSearchPrices(values) {

            let matchedCategory = null;

            for (const [categoryName, category] of Object.entries(this.pricesList)) {
                if (category.name.toLowerCase() === this.stepData.companyselection.toLowerCase()) {
                    
                    matchedCategory = category;
                    break; 
                }
            }

            if (matchedCategory) {

                //This is if companyannualsales.value is blank or Zero, no reason to continue
                if((values.value * 1) == 0) {
                    this.stepData.yearlyCost = '';
                    this.yearlyCostError = [false, 1];

                    this.stepData = {...this.stepData, yearlyCost: this.stepData.yearlyCost, yearlyCostError: this.yearlyCostError, companyannualsales: 0, pass: true};
                    this.changeContractSteps({step01: this.stepData});
                    
                    return;
                }

                
                if(values.value > matchedCategory.prices[matchedCategory.prices.length - 1].range) {
                    this.stepData.yearlyCost = '';
                    this.yearlyCostError = [true, 1];

                    this.stepData = {...this.stepData, yearlyCost: this.stepData.yearlyCost, yearlyCostError: this.yearlyCostError, pass: false};
                    this.changeContractSteps({step01: this.stepData});
                    
                    return;
                }

                for (let i = matchedCategory.prices.length - 1; i >= 0; i--) {
                    const price = matchedCategory.prices[i];
                    if (values.value <= price.range) {
                        this.stepData.yearlyCost = `${price.value} €`;
                        this.yearlyCostError = [false, 1];

                        this.stepData = {...this.stepData, companyannualsales: (this.stepData.companyannualsales * 1)};
                        this.stepData = {...this.stepData, yearlyCost: this.stepData.yearlyCost, yearlyCostError: this.yearlyCostError, pass: true};

                        this.changeContractSteps({step01: this.stepData});

                    }
                }
                
            }
        },
    },
    computed: {
        ...mapState(['imagePath', 'updateView', 'country', 'contractSteps']),
    },
    watch: {
        'contractSteps': {
            handler(newdata) {
                this.stepData = newdata.step01
            }
        }
    },
    mounted() {

        if(!this.contractSteps.hasOwnProperty(this.stepName)){

            this.stepData = {...this.stepData};
            this.changeContractSteps({step01: this.stepData});

        } else {
            this.stepData = this.contractSteps.step01;
        }

    }
}
</script>