<template>
  <div id="cyberhelp" class="bkg_turkish">
    <component :is="selectedLanguage" @passLanguage="setLanguage" />
    <div class="content">
      <h1> {{ locallanguage.title }}</h1>
      <div class="tribles"> 
        <div class="items">
          <h4 v-html="locallanguage.hotlinetitle"></h4>
          <p v-html="locallanguage.hotlinelabel"></p>
          <br>
          <h4 v-html="locallanguage.analysetitle"></h4>
          <p v-html="locallanguage.analyselabel"></p>
        </div>
        <div class="items">
          <h4 v-html="locallanguage.networktitle"></h4>
          <p v-html="locallanguage.networklabel"></p>
          <div class="desktop" align="center">
            <br/><br/>
            <contractform/>
          </div>
        </div>
        <div class="items">
          <h4 v-html="locallanguage.accesstitle"></h4>
          <p v-html="locallanguage.accesslabel"></p>
        </div>
        <div class="wide mobile">
          <contractform/>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="pricesFrame">
        <h1>{{ locallanguage.price }}</h1>
        <contentPrices/>
      </div>
      <div align="right" class="spanmwst"><span>{{ locallanguage.taxlabel }}</span></div>
    </div>
  </div>
</template>

<style src="../../../components/parts/cyberhelp/style.css"></style>
      
<script>

import { mapState } from 'vuex'; 

export default {
  data() {
    return {
        locallanguage: {
            title:'', hotlinetitle: '', hotlinelabel: '', analysetitle:'', analyselabel: '', networktitle:'', networklabel:'', accesstitle:'' ,accesslabel: '', taxlabel: '', price: ""
        }
    }
  },
  methods: {
    setLanguage(locallanguage) {
      this.locallanguage = locallanguage
    }
  },
  computed: {
    ...mapState(['imagePath',  'updateView', 'language']),
    selectedLanguage() {
        return this.language+'component';
    },
  },
  components: {
    'contractform': require('@/components/tools/contractform/content.vue').default,
    'contentPrices': require('@/components/contents/prices/content.vue').default,
    'DEcomponent': require('@/components/parts/cyberhelp/DE.vue').default,
    'FRcomponent': require('@/components/parts/cyberhelp/FR.vue').default
  }
}
</script>