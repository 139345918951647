<template>
    <div class="p-yscroll-content" id="contractinformation">
        <div class="frame">
            <div class="title">Préambule</div>
            <p>COGITANDA® assiste les entreprises et les particuliers en cas d’incident cyber. COGITANDA® propose un produit qui se divise en deux parties indépendantes l‘une de l‘autre. La première partie de la prestation, appelée « COGITANDA® Cyber Aide d’Urgence » comprend la mise à disposition d‘une hotline d‘urgence 24h/24 et 7j/7 pour signaler un éventuel incident cyber, la mise en place de mesures immédiates et l‘intervention de spécialistes cyber de notre réseau dans la limite d’un budget total de 7500€. Le présent contrat de services régit ce premier volet de prestations</p>
            <p>La deuxième partie du service, intitulée « COGITANDA® Cyber Gestion de Crise », devra faire l‘objet d‘un contrat de service séparé du présent contrat et signé par le client.</p>
            <p>Ceci étant dit, les Parties concluent le contrat suivant : </p>
            <div class="title">Article 1 Objet du contrat</div>

            <ol>
                <li>
                    Le Client confie à COGITANDA® la réalisation des services suivants :
                    <ol type="a">
                        <li>
                            <span class="subtitle">Accès téléphonique à une hotline d‘urgence 24h/24</span>
                            <ul class="custom-bullet">
                                <li>Le numéro unique de la hotline d‘urgence est le suivant : + 33 1 86 65 45 26</li>
                                <li>Consultation et enregistrement de toutes les informations pertinentes</li>
                                <li>Transmission à l‘équipe d‘experts COGITANDA®</li>
                            </ul>
                        </li>
                        <li>
                            <span class="subtitle">Mise en place de mesures immédiates</span>
                            <ul class="custom-bullet">
                                <li>Entretien avec les experts COGITANDA® dans les 2 heures </li>
                                <li>Analyse de la situation sur la base des informations transmises par le Client </li>
                                <li>Évaluation initiale de l’incident cyber</li>
                                <li>Conseil sur les options d‘actions possibles</li>
                            </ul>
                        </li>
                        <li>
                            <span class="subtitle">Intervention de spécialistes cyber dans les premières 48h dans la limite d’un budget de 7.500 EUR</span>
                            <ul class="custom-bullet">
                                <li>Cellule de crise : mise en place et coordination</li>
                                <li>Gestion du sinistre et communication de crise</li>
                                <li>IT-Forensic : recherche de traces, analyse, évaluation</li>
                                <li>Remise en état informatique : planification, initialisation, déploiement</li>
                                <li>Évaluation de la protection des données</li>
                            </ul>
                        </li>
                    </ol>
                </li>
                <li>
                    Le Client a l‘option de recourir au réseau d‘experts hautement spécialisés et aux capacités de COGITANDA® dans le cadre de la gestion de crise cyber (COGITANDA® Cyber Gestion de Crise). Cela doit faire l’objet d’un contrat séparé.

                </li>
                <li>
                    COGITANDA® fournit les services dans le cadre d‘un contrat de prestation de services. COGITANDA® s‘engage à fournir les services conformément à l‘état des connaissances techniques généralement reconnues et des lois applicables.
                </li>
                <li>
                    COGITANDA® n‘est pas tenue à une obligation de résultat, mais uniquement à une obligation de moyen, et n‘assume ou n‘ accorde aucune garantie en ce qui concerne les prestations contractuelles et/ou autres prestations.
                </li>
            </ol>

            <div class="title">Article 2 Partenaires de services</div>
            <ol>
                <li>
                    COGITANDA® est autorisée à faire appel à des tiers (« Partenaires de services ») pour la fourniture des services.
                </li>
                <li>
                    COGITANDA® choisit pour chaque cas le Partenaire de services adéquat dans son propre réseau de Partenaires de services. Le client n‘est pas autorisé à recourir à un prestataire de services spécifique.
                </li>
            </ol>

            <div class="title">Article 3 Durée du contrat, résiliation et condition résolutoire</div>
            <ol>
                <li>
                    Le présent contrat entre en vigueur dès sa signature par les Parties et est conclu pour une durée déterminée d‘un an. Le contrat est automatiquement reconduit pour une année supplémentaire s‘il n‘est pas résilié avec un préavis de trois mois avant son expiration.
                </li>
                <li>
                    Le droit de résilier le contrat avant l‘arrivée du terme en raison d‘une inexécution suffisamment grave n‘est pas affecté. Si le motif grave est lié au comportement de l‘une des Parties, une résiliation extraordinaire n‘est en règle générale autorisée que si l‘autre Partie a préalablement donné un préavis dans un délai raisonnable qui ne doit pas être supérieur à 14 jours, de modifier son comportement ou d‘annuler les conséquences qui en découlent. Le droit de faire valoir d’autres revendications, notamment la demande de dommage et intérêt, n’est pas affecté.
                </li>
                <li>
                    Le contrat prend fin si le client souscrit un contrat d’assurance cyber auprès d‘une société du groupe COGITANDA® (condition résolutoire). Dans ce cas, les frais relatifs à ce contrat seront remboursés à partir de la date de début du contrat d’assurance cyber.
                </li>
                <li>
                    La résiliation et, le cas échéant, la mise en demeure préalable doivent être effectuées par écrit (lettre recommandée avec accusé de réception sous forme électronique ou par voie postale).
                </li>
            </ol>

            <div class="title">Article 4 Liberté d’action</div>
            <p>
                COGITANDA®, n‘est soumise à aucune instruction du Client en ce qui concerne la répartition du temps et l‘organisation du déroulement des activités dans le cadre de l‘exécution du contrat et des prestations de services qu‘elle effectue.
            </p>

            <div class="title">Article 5 Honoraires</div>
            <ol>
                <li>
                    COGITANDA® perçoit pour ses prestations de service pendant la durée du contrat un montant forfaitaire fixé dans <b>l‘annexe 1.</b> En cas de prolongation de la durée du contrat, les honoraires sont à nouveau dûs pour chaque année supplémentaire.
                </li>
                <li>
                    Le montant doit être payé dans son intégralité dans les 14 jours suivant la conclusion du contrat. Le Client ne peut compenser les créances de COGITANDA® qu‘avec des créances fongibles, exigibles, non contestées et faisant l’objet d‘un titre exécutoire.
                </li>
                <li>
                    COGITANDA® n‘est pas tenue de rembourser la totalité ou une partie des honoraires payés en cas de résiliation anticipée du contrat, et le Client n‘a aucun droit à un tel remboursement. La souscription d‘une assurance cyber auprès d‘une société du groupe COGITANDA® constitue une exception. Dans ce cas, les honoraires payés seront remboursés au prorata du temps non écoulé.
                </li>
            </ol>

            <div class="title">Article 6 Obligation de confidentialité</div>
            <p>
                Les Parties s‘engagent, ainsi que leurs collaborateurs et leurs Partenaires de services, à respecter le secret de toutes les informations confidentielles mises à leur disposition dans le cadre du présent contrat, en particulier les informations couvertes par le secret des affaires de l‘autre Partie. Une information est confidentielle lorsqu‘elle est explicitement désignée comme telle ou lorsque son caractère confidentiel résulte manifestement des circonstances particulières. Cette obligation de confidentialité se poursuit pendant la durée d‘un an après la fin du présent contrat.
            </p>

            <div class="title">Article 7 Limitation et exclusion de la responsabilité de COGITANDA®</div>
            <ol>
                <li>
                    COGITANDA® n‘est responsable des dommages causés par ses services qu‘en cas de dol ou de faute lourde de ses collaborateurs ou partenaires chargés de l‘exécution de ces services. Dans les autres cas, sa responsabilité est exclue, à moins qu‘il ne s‘agisse d‘un manquement à une obligation essentielle pour réaliser l‘objectif même du contrat.
                </li>
                <li>
                    Sauf en cas de dol ou de faute lourde, la responsabilité de COGITANDA® est limitée par incident cyber au montant des honoraires dûs pour l‘année en cours.
                </li>
                <li>
                    Sauf en cas de dol ou de faute lourde, la responsabilité de COGITANDA® est exclue pour les dommages indirects et consécutifs, notamment les dommages immatériels. En particulier, COGITANDA® n‘est pas responsable du manque à gagner, de la perte d‘exploitation, de l‘interruption d‘activité, de la perte ou de la modification d‘informations ou de données, notamment de la modification d‘une structure de données du Client.
                </li>
                <li>
                    Si le contrat inclut la récupération de données, COGITANDA® n‘est pas responsable de la perte de données et/ou de programmes si le dommage est dû au fait que le Client a omis de procéder à des sauvegardes de données et de s‘assurer ainsi que les données perdues pouvaient être récupérées moyennant des efforts raisonnables.
                </li>
                <li>
                    Les exclusions et limitations de responsabilité susmentionnées ne s‘appliquent pas en cas d‘atteinte à la vie, à l‘intégrité physique ou à la santé, ou en cas de garantie spécifiquement offerte par COGITANDA®.
                </li>
            </ol>

            <div class="title">Article 8 Force majeure</div>
            <p>
                Conformément à l‘article 1218 du Code civil, il y a force majeure lorsqu‘un événement échappant au contrôle du débiteur, qui ne pouvait être raisonnablement prévu lors de la conclusion du contrat et dont les effets ne peuvent être évités par des mesures appropriées, empêche l‘exécution de l‘obligation par le débiteur. La force majeure libère les Parties de leurs obligations d‘exécuter le contrat pendant la durée de l‘empêchement et dans la mesure de ses effets.
            </p>

            <div class="title">Article 9 Dispositions finales</div>
            <ol>
                <li>
                    Le contrat est régi par le droit français. 
                </li>
                <li>
                    Tout litige relatif à la conclusion, la validité, l‘interprétation, l‘exécution ou la résiliation du présent contrat relève de la compétence exclusive du Tribunal de Commerce de Paris. 
                </li>
                <li>
                    Toute modification et tout ajout à ce contrat ne sont valables que sous forme écrite. Cela vaut également pour la suppression de cette exigence de forme écrite.
                </li>
                <li>
                    Si certaines dispositions de ce contrat sont ou deviennent nulles ou réputées non écrites, la validité des autres dispositions ne s’en trouve pas affectée. Les Parties sont tenues de remplacer la disposition non-valable par une disposition permettant d‘atteindre, dans la mesure du possible, le résultat escompté. Il en va de même en cas de lacunes du contrat.
                </li>
            </ol>

            <div class="turkishframe">
                <div class="row">
                    <div class="col50">
                        <h1>Annexe 1</h1>
                    </div>
                    <div class="col50">
                        <h2 class="float-right">GRILLE TARIFAIRE</h2>
                    </div>
                    <div class="col100 no-padding-margin">
                        <small class="float-right">En vigueur au 05.10.2023</small>
                    </div>
                </div>
            </div>
            <contentPrices/>
        </div>
    </div>
</template>

<script>

export default {
    components: {    
        'contentPrices': require('@/components/contents/prices/content.vue').default
    },
}

</script>