<template></template>
<script>
export default {
    created() {
        const language = {
            title: "FÜR EIN INDIVIDUELLES BERATUNGSGESPRÄCH STEHEN WIR IHNEN GERNE ZUR VERFÜGUNG.", 
            name_label: "NAME *", 
            email_label: "EMAIL *", 
            telephone_label: "TELEFONNUMMER *", 
            message_label: "IHRE NACHRICHT", 
            call_label: "Bitte um Anruf", 
            privacy_label: "Ich habe die Datenschutzleitlinie gelesen",
            required: "* Pflichtfeld",
            send: "Nachtricht Senden"
        };
        this.$emit('passLanguage', language)
    }
}
</script>