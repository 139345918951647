<template>
    <div class="inner lightgraybkg">
        <div class="d-floatleft">
            <div class="d-content form" id="step03">
            <div class="title">
                Contact
            </div>

            <div class="p-width100">
                <select 
                    v-model="stepData.contacttitle"
                    class="p-inputtext p-component reqsubmit">
                    <option value="Madame">Madame</option>
                    <option value="Monsieur">Monsieur</option>
                    <option value="Veuillez sélectionner une civilité">Veuillez sélectionner une civilité</option>
                </select>
                <span>Civilité *</span>
            </div>

            
            <div class="p-width100">
                <input 
                v-model="stepData.contactfirstname"
                id="contactfirstname"  
                type="text" 
                class="p-inputtext p-component reqsubmit"
                />
                <span>Prenom *</span>
            </div>

            <div class="p-width100">
                <input 
                v-model="stepData.contactfamilyname"
                id="contactfamilyname" 
                type="text" 
                class="p-inputtext p-component reqsubmit"
                />
                <span>Nom *</span>
            </div>

            <div class="p-width100" >
                <input 
                    v-model="stepData.companyemail"
                    id="companyemail" 
                    type="email" 
                    class="p-inputtext p-component reqsubmit"
                    />
                <span>E-Mail (professionnel) *</span>
            </div>

            <div class="p-width100 p-margin15B">
                <input 
                    v-model="stepData.companymobile"
                    id="companymobile"  
                    type="text"
                    class="p-inputtext p-component reqsubmit"
                    />
                    <span>Portable (professionnel) *</span>
            </div>
            <div class="p-width100">
                <input 
                    v-model="stepData.contactemail"
                    id="contactemail"  
                    type="text"
                    class="p-inputtext p-component reqsubmit"
                    />
                <span>E-Mail (privé – urgences) * <label class="circle" @click="infoText()">i</label></span>
            </div>

            <div class="p-width100">
                <input 
                    v-model="stepData.contactmobile"
                    id="contactmobile"  
                    type="text"
                    class="p-inputtext p-component reqsubmit"
                    />
                <span>Portable (privé – urgences) * <label class="circle" @click="infoText()">i</label></span>
            </div>
        </div>
    </div>
    <div class="d-floatright">
        <div class="d-content form" id="formBoard">
            <div class="divscrolly">
                <div class="tab-error" v-if="infoTextView">
                    <u>Informationstext:</u> ll est essentiel de disposer d'un numéro de téléphone et d'un email privé en cas de cyber-attaque. Dans certaines situations, le système téléphonique peut tomber en panne ou l'accès aux boîtes aux lettres électroniques peut être refusé au sein de l'entreprise. ll est essentiel de disposer d'un contact direct pour pouvoir réagir rapidement aux incidents de sécurité.
                </div>
                <div class="tab-error" v-if="stepData.stepPass==false">
                    Veuillez remplir tous les champs obligatoires pour continuer.
                </div>
                <div>&nbsp;</div>
            </div>
        </div>
    </div>
    <div class="d-floatclear form" style="padding: 0!important; height: 1px!important">&nbsp;</div>
</div>  
</template>
  
<style src="../../../../components/tools/contractform/FR/step03.css"></style>
  
<script>

import { mapState, mapActions } from 'vuex'; 

export default {
    data() {
        return {
            stepName: 'step03',
            infoTextView: false,
            stepData: {
                contacttitle: 'Veuillez sélectionner une civilité',
                contactfirstname: '',
                contactfamilyname: '',
                companyemail: '',
                companymobile: '',
                contactemail: '',
                contactmobile: '',
            }
        }
    },
    computed: {
        ...mapState(['imagePath', 'updateView', 'country', 'contractSteps']),
    },
    watch: {
        'contractSteps': {
            handler(newdata) {
                this.stepData = newdata.step03;
            }
        }
    },
    methods: {
        ...mapActions(['updateContractSteps']),
        changeContractSteps(newContractSteps) {
            this.$store.dispatch('updateContractSteps', newContractSteps);
        },
        infoText() {
            this.infoTextView = !this.infoTextView;
        }
    }, 
    mounted() {
        if(!this.contractSteps.hasOwnProperty(this.stepName)){

            this.stepData = {...this.stepData};
            this.changeContractSteps({step03: this.stepData});

        } else {
            this.stepData = this.contractSteps.step03;
        }
    }
}
</script>