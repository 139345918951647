<template>
    <div class="p-yscroll-content">
        <p><b>Anbieterkennzeichnung und Pflichtangaben nach § 5 TMG.</b></p>
        <p>Diensteanbieter im Sinne von § 5 TMG ist die</p>
        <p>COGITANDA Claims Services GmbH<br>Münstereifeler Str. 1<br>53505 Altenahr (Kreuzberg)</p>
        <p>
            <a href="mailto:info@cogitanda.com">info@cogitanda.com</a><br>
            Tel.:<a href="tel:+4922199575200">+49(0)221 995 752 06</a><br>Fax.: +49(0)221 669 503 59</p>
        <p><b>vertreten durch die Geschäftsführer:</b><br>Nicolai Wojciechowski<br>Tim Sievers<br>Christian Sagawe</p>
        <p>
            <b>Sitz der Gesellschaft:</b>Altenahr<br />
            <b>Registergericht:</b>Amtsgericht Koblenz<br />
            <b>Handelsregisternummer:</b>HRB 25672<br>
            <b>Umsatzsteuer-ID-Nr.:</b>DE 313894877
        </p>
        <p><b>Aufsichtsbehörde/ Erlaubnisbehörde/ zuständige Berufskammer:</b><br>IHK Koblenz, Schlossstr. 2, 56068 Koblenz, <a href="https://www.ihk.de/koblenz/" target="_blank" rel="nofollow">https://www.ihk.de/koblenz/</a></p>
        <p><b>Haftungsausschluss:</b><br>Für die Inhalte externer Links übernehmen wir trotz sorgfältiger inhaltlicher Prüfung keine Haftung. Den Inhalt verlinkter Seiten verantwortet deren Betreiber.</p>
        <p><b>Was bedeutet COGITANDA?</b><br>Was bedeutet der Name COGITANDA? "COGITANDA" ist kein Phantasiebegriff, er stammt aus dem Lateinischen. Dieses Gerundivum des Wortes "cogitare" übersetzen wir mit: "Die Dinge, über die man nachdenken sollte."</p>
        <p><b>Eingetragene Marken</b><br><b>COGITANDA</b>ist in Deutschland, dem Vereinigten Königreich und in der gesamten EU geschützt. COGITANDA ist eine eingetragene Marke.</p>
        <p><b>Umsetzung der Website:</b><br>COGITANDA Claims Services GmbH<br>Münstereifeler Str. 1<br>53505 Altenahr (Kreuzberg)<br></p>
    </div>
</template>
<script>

export default {
}

</script>