<template>
    <div id="prices">
        <div class="table" :class="(this.language=='FR')? 'FR':''">
            <FRcontent v-if="this.language=='FR'"/>
            <DEcontent v-else/>
        </div>
    </div>
</template>

<style src="../../../components/contents/prices/style.css"></style>

<script>

import { mapState } from 'vuex'; 

export default {
  computed: {
    ...mapState(['language']),
  },
  components: {
    'DEcontent': require('@/components/contents/prices/DE.vue').default,
    'FRcontent': require('@/components/contents/prices/FR.vue').default
  }
}

</script>