<template>
    <div>
        <div id="step07">
            <div class="inner form">
                <div class="frame">
                    <p>
                        Pour des raisons de sécurité, vos informations ont été effacées suite à une inactivité de plus de 5 minutes
                    </p>
                    <img class="button" :src="imagePath + 'contractform/fillup-FR01.webp?'+updateView" @click="refill" />
                </div>
            </div>
        </div>
    </div>
</template>
  
<style src="../../../../components/tools/contractform/FR/step07.css"></style>
  
<script>
import { mapState } from 'vuex'; 
export default {
    computed: {
        ...mapState(['imagePath', 'updateView', 'country', 'contractSteps']),
    },
    methods: {
        refill() {
            localStorage.setItem('contractform.refill-FR', 1);
            window.location.reload();
        }
    }
}
</script>