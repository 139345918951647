<template></template>
<script>
export default {
    created() {
        const language = {
            title1: 'SIE DENKEN, NUR GROSSE KONZERNE GERATEN INS FADENKREUZ DER HACKER?', 
            subtitle1: 'Cyber Kriminalität ist ein lukratives Geschäft für gewerblich organisierte Banden. Längst werden gezielt Unternehmen aller Größenordnungen attackiert.', 
            title2: 'SIE DENKEN, IHR IT-DIENSTLEISTER MACHT DAS SCHON?', 
            subtitle2: 'Im Falle eines erfolgreichen Angriffs müssen neben der IT-Forensik auch die Betriebsorganisation, das Krisenmanagement sowie die interne und externe Kommunikation koordiniert werden.', 
            label24: 'Nach einer Cyber Attacke steht oft der gesamte Betrieb still. Es zählt jede Minute, um längere Ausfallzeiten und damit wirtschaftlichen Schaden wie auch reputationbezogene Verluste zu verhindern. <br>Mit der <b>COGITANDA<sup><small>®</small></sup> Cyber Sofort Hilfe</b> sind Sie bestens vorbereitet, denn wir sind 365/24/7 erreichbar und starten bereits 2 Stunden nach Ihrer Meldung bei uns mit konkreten Analysen und Sofortmaßnahmen in Ihren Systemen.'
        };
        this.$emit('passLanguage', language)
    }
}
</script>