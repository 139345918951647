<template></template>
<script>
export default {
    created() {
        const language = {
            imprint: 'MENTIONS LÉGALES', 
            dataprotection: 'POLITIQUE DE CONFIDENTIALITÉ', 
            dataprotectionbusiness: 'PROTECTIONS DES DONNÉES DE NOS PARTENAIRES',
            dataprotectionbusinesstitle: 'INFORMATION SUR LA PROTECTION DES DONNÉES DES PARTENAIRES COMMERCIAUX'
        };
        this.$emit('passLanguage', language)
    }
}
</script>