<template></template>
<script>
export default {
    created() {
        const language = {
            title: "N'HESITEZ PAS A NOUS CONTACTER POUR UN DEVIS PERSONNALISE", 
            name_label: "NOM *", 
            email_label: "EMAIL *", 
            telephone_label: "NUMERO DE TELEPHONE *", 
            message_label: "VOTRE MESSAGE…", 
            call_label: "Être rappelé", 
            privacy_label: "J'ai lu la politique de confidentialité",
            required: "* Champ obligatoire",
            send: "envoi nocturne"
        };
        this.$emit('passLanguage', language)
    }
}
</script>