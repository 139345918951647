<template>
  <div id="formula" class="bkg_turkish">
    <component :is="selectedLanguage" @passLanguage="setLanguage" />
    <div class="content">
      <div class="chatbox">
        <h2>{{ locallanguage.title }}</h2>
      </div>
      <div class="tribles">
        <div class="items" 
          :class="{ inactive:activeBox[1] == true }">
            <input
              v-model="fullname"
              id="fullname"
              type="text"
              :class="{ valid:isValidName == true, invalid:isValidName == false }"
              @input="checkErrors"
              @click="activateValidation(1)"
              :placeholder="locallanguage.name_label"
            />
        </div>
        <div class="items"
          :class="{ inactive:activeBox[2] == true }">
            <input 
              v-model="email" 
              id="email" 
              type="email" 
              :class="{ valid:isValidEmail == true, invalid:isValidEmail == false}"
              @input="checkErrors"
              @click="activateValidation(2)"
              :placeholder="locallanguage.email_label"/>
        </div>
        <div class="items"
          :class="{ inactive:activeBox[3] == true }">
            <input 
              v-model="telephone"
              id="telephone"
              type="text"
              :class="{ valid:isValidPhone == true, invalid:isValidPhone   == false}"
              @input="checkErrors"
              @click="activateValidation(3)"
              :placeholder="locallanguage.telephone_label"/>
        </div>

        <textarea 
          v-model="message"
          id="message" 
          :placeholder="locallanguage.message_label"></textarea>

        <div class="checklabels">
          <div class="cbox">
          <input 
            type="checkbox" 
            id="callme" 
            v-model="callme">
            <label for="callme">{{ locallanguage.call_label }}</label>
          </div>
          <div class="cbox"
              :class="{ invalidate:!dataprotectionTrue }">
            <input 
              type="checkbox" 
              id="dataprotection"
              @click="dataprotectionValidation(); checkErrors()"
              v-model="dataprotection" field="required">
              <label 
                :class="{ error:!dataprotection }"
                @click="dataprotectionValidation(); checkErrors()"
                for="dataprotection"
                required>{{ locallanguage.privacy_label }}</label>
          </div>
          <div class="cbox float-right">
            <label>{{ locallanguage.required }}</label>
          </div>
        </div>

        <div class="send" align="right">
          <!--<form  @submit.prevent="handleSubmit">
            
              <trustcaptcha-component
                ref="trustcaptchaRef"
                sitekey="ae477fd0-b2f1-4b11-a4cd-997099d3fd17"
                language="fr"
                theme="light"
                autostart="active"
                tokenFieldName="tc-verification-token"
                @captchaSolved="handleCaptchaSolved($event.detail)"
                @captchaFailed="handleCaptchaFailed($event.detail)"
              ></trustcaptcha-component>
          </form>
          <br>
            <p>Captcha Solved: {{ captchaResult }}</p>
            <p>Captcha Failed: {{ captchaError }}</p>-->
          <img 
            class="button" 
            :alt="locallanguage.send"
            @click="sendMessage()" 
            width="500" height="71"
            :src="errors[4].total ? imagePath + 'buttons/sendmessage_'+this.language+'_error.png?'+updateView : imagePath + 'buttons/sendmessage_'+this.language+'_ready.png?'+updateView"
            />
        </div>
      </div>
    </div>
    <thankyouFormulaFR v-if="this.language=='FR'"/>
    <thankyouFormulaDE v-else/>
  </div>
</template>

<style src="../../../components/parts/formula/style.css"></style>
    
<script>
import axios from "axios";
import { mapState } from 'vuex'; 

import ApiService from "../../../captchaService";

export default {
  name: "App",
  data() {
    return {

      captchaText:'',
      siteKey: '6LfP-6UpAAAAANq81M7GcVWsN81Q4L0boaUH1lYf',
      recaptchaResolved: false,

      verificationResult: null,
      form: {
        message: '',
        verificationToken: null
      },

      fullname: '',
      email: '',
      telephone: '',
      message: '',
      callme: false,
      dataprotection: false,
      dataprotectionTrue: false,
      errors: [],
      activeBox: [true, true, true, true],
      locallanguage: {
        title: '', name_label: '', email_label: '', telephone_label: '', message_label: '', call_label: '', privacy_label: '', required: ""
      }
    }
  },
  computed: {
    ...mapState(['imagePath',  'updateView', 'language']),
    selectedLanguage() {
        return this.language+'component';
    },
    isValidName() {
      let text = this.fullname.replace(/\s/g, "");
      let regex = /^[a-zA-ZäöüÄÖÜß\s,\.\-]+$/;
      let result = text !== "" && regex.test(text);
      
      if(!result) {
        regex = /^[A-Za-zÀ-ÖØ-öø-ÿ'-]+$/;
        result = text !== "" && regex.test(text);
      }
      
      this.checkErrors();

      return result;
    },
    isValidEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
      const result = this.email !== "" && emailRegex.test(this.email);
      this.checkErrors();
      return result;
    },
    isValidPhone() {
      const phoneNumber = this.telephone.replace(/\s+/g, '');
      const phoneRegex01 = /^\+[0-9()\-\s]{9,}$/;                 //  +49 (0201) 55-55-55
      const phoneRegex02 = /^[0-9()\-]{9,}$/;                     //  0049 0201 55 55 55
      const result = this.telephone !== "" && ((phoneRegex01.test(phoneNumber))? true: phoneRegex02.test(phoneNumber));
      this.checkErrors();
      return result;
    },
  },
  components: {
    'thankyouFormulaDE': require('@/components/parts/formula/thankyou.de.vue').default,
    'thankyouFormulaFR': require('@/components/parts/formula/thankyou.fr.vue').default,
    'DEcomponent': require('@/components/parts/formula/DE.vue').default,
    'FRcomponent': require('@/components/parts/formula/FR.vue').default
  },
  methods: {
    handleCaptchaSolved(eventDetail) {
      // This method will be triggered when captcha is successfully solved
      this.captchaResult = eventDetail;  // Capture the result from event detail
      console.log('Captcha Solved:', eventDetail);  // Log for debugging
    },
    handleCaptchaFailed(eventDetail) {
      // This method will be triggered if captcha failed
      this.captchaError = eventDetail;  // Capture the failure reason or token
      console.log('Captcha Failed:', eventDetail);  // Log for debugging
    },
    solved(verificationToken) {
      console.log(`Verification-token: ${verificationToken}`);
      this.form.verificationToken = verificationToken;
    },
    failed(error) {
      console.Error(error.message);
    },
    handleSubmit() {
      console.log('-submited');
      ApiService.postApi(this.form.verificationToken).then(verificationResult => this.verificationResult = verificationResult);
    },
    resetCaptcha() {
      this.$refs.trustcaptchaRef.reset(); // Aufruf der reset() Methode über die Referenz
      this.form.message = ''; // Zurücksetzen der Nachricht
      this.verificationResult = null; // Zurücksetzen des Verifizierungsergebnisses
    },
    setLanguage(locallanguage) {
      this.locallanguage = locallanguage
    },
    checkErrors() {
      this.errors = [
        {isValidName: ((!this.isValidName)? false:true)}, 
        {isValidEmail: ((!this.isValidEmail)? false:true)}, 
        {isValidPhone: ((!this.isValidPhone)? false:true)}, 
        {dataprotection: ((!this.dataprotection)? false:true)},
        {
          total:((!this.isValidName)? 1:0) +
                ((!this.isValidEmail)? 1:0) + 
                ((!this.isValidPhone)? 1:0) + 
                ((!this.dataprotection)? 1:0)
        } 
      ];
    },
    activateValidation(boxNumber) {
      if (this.activeBox[boxNumber] === true) {
        this.activeBox[boxNumber] = false;
      }
      console.log('errors', this.errors);
    },
    dataprotectionValidation() {
      this.dataprotectionTrue = true;
      this.checkErrors();
    },
    sendMessage() {
      document.getElementById("popup").classList.add('hideThis');
      this.activeBox = [false, this.isValidName, this.isValidEmail, this.isValidPhone, this.dataprotection];
      this.dataprotectionTrue = true;

      this.checkErrors();

      if(this.errors[4].total > 0) {
        console.log(this.errors);
        return;
      }

      console.log("Preparing data values");
      let url = process.env.VUE_APP_CSH_SERVICE_URL + '/csh/service/emailContactForm'
      let data = {};
      data['name'] = fullname.value;
      data['email'] = email.value;
      data['phoneNumber'] = telephone.value;
      data['messageText'] = message.value;
      data['callOnPhoneAccepted'] = callme.checked;
      data['dataProtectionAccepted'] = dataprotection.checked;
      //console.log('data: ', data);
      
      axios.post(url,data).then((response) => {
        console.log("Sent!");
        document.getElementById("popup").classList.add('viewThis');

      }).catch((error) => {
        console.log('error',error);
      });

    }
  }
}
</script>