<template>
    <div class="p-yscroll-content">
        <p>Diese Datenschutzinformation für Geschäftspartner unterrichtet Sie über die Verarbeitung Ihrer personenbezogenen Daten bei der COGITANDA Gruppe.</p>
        <p>Zu Ihren personenbezogenen Daten gehören gem. Art. 4 lit. 1 DS-GVO alle Informationen, die sich auf Ihre Person beziehen oder beziehen lassen, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen oder zu einer Organisations- oder Kundennummer, mit der Ihre Person identifiziert werden kann.</p>
        <p>Wir stellen diese Datenschutzinformation für unsere Geschäftspartner zur Verfügung ("Mitteilung"), um als verantwortliche Stelle die Datenverarbeitungsprozesse bezüglich unserer Lieferanten, Kunden und Geschäftspartnern (kollektiv, "Geschäftspartner") und deren Mitarbeitern, die mit der COGITANDA Gruppe in Verbindung stehen, zu erläutern.</p>
        <h2>Umfang:</h2>
        <p>Diese Mitteilung gilt, wenn Sie ein selbständiger Geschäftspartner eines der im Impressum genannten Unternehmen der COGITANDA Gruppe (z. B. als Kooperationsmakler) sind oder wenn Sie ein Mitarbeiter von einem Geschäftspartner sind, der im Auftrag von dem Geschäftspartner mit der COGITANDA Gruppe agiert.</p>
        <h2>Kategorien personenbezogener Daten und Datenquellen:</h2>
        <p>Die COGITANDA Gruppe verarbeitet folgende personenbezogene Daten von Ihrem Unternehmen oder von Dritten (z. B. Ihrem Vorgesetzten, Behörden oder öffentliche Ressourcen):</p>
        
        <ul>
            <li>
                Personenbezogene Daten in Bezug auf selbstständige Geschäftspartner: Name, geschäftliche Kontaktdaten, angebotene Dienstleistungen oder Produkte, Vertragsinformationen, Kommunikationsinhalte (wie E-Mails oder geschäftliche Briefe), Zahlungsinformationen, Rechnungsinformation und Geschäftsbeziehungsverlauf
            </li>
            <li>
                Personenbezogenen Daten in Bezug auf Mitarbeiter eines Geschäftspartners: Name, geschäftliche Kontaktdaten, Name des Arbeitgebers, Titel/Position und Kommunikationsinhalte (wie E-Mails oder geschäftliche Briefe)
            </li>
        </ul>

        <h2>Datenverarbeitungszwecke, Rechtsgrundlage und Konsequenzen:</h2>
        <p>Ihre personenbezogenen Daten werden für Zwecke der Erfüllung der Vertragsverhältnisse mit dem Geschäftspartner (einschließlich der Erfüllung von vertraglichen Leistungspflichten, Rechnungsbearbeitung, Kommunikation und Einhaltung der gesetzlichen Vorschriften) für Marketing und CRM Tätigkeiten und für Betrugsprävention verarbeitet.</p>

        <ul>
            <li>
                Erfüllung des Vertragsverhältnisses mit dem Geschäftspartner (Art. 6 lit. b DS-GVO);
            </li>
            <li>
                Berechtigtes Interesse von einem der Unternehmen der COGITANDA Gruppe oder Dritten (z. B. staatliche Stellen oder Gerichte) (Art. 6 lit. f DS-GVO). Das berechtigte Interesse kann insbesondere der COGITANDA gruppenweite Informationsaustausch, Marketing und CRM Tätigkeiten sein, Verhinderung von Betrug, IT Missbrauch, Geldwäsche, Betrieb eines Hinweisgebersystems, physische Sicherheit, IT- und Netzwerksicherheit, interne Ermittlungen oder auch potenzielle Fusionen und Übernahmetätigkeiten
            </li>
            <li>
                Einwilligung (Art. 6 lit. a DS-GVO);
            </li>
            <li>
                Erfüllung rechtlicher Verpflichtungen (Art. 6 lit. c DS-GVO);
            </li>
        </ul> 

        <p>Die Bereitstellung von personenbezogenen Daten ist für den Abschluss und/oder die Durchführung des Geschäftspartnervertrages erforderlich und freiwillig. Sollten Sie diese Daten allerdings nicht zur Verfügung stellen, könnte es die Geschäftspartner-Management- und die administrativen Prozesse verzögern oder auch unmöglich machen.</p>

        <h2>Empfänger Kategorien:</h2>
        <p>Die Unternehmen der COGITANDA Gruppe dürfen Dienstleister, die als Verarbeiter agieren, einsetzen, damit diese IT- und andere administrative Unterstützung leisten (z. B. Dienstleister, die Unterstützung bei Kreditorenbuchhaltung oder IT-Hosting und Wartungsunterstützung anbieten). Diese Dienstleister haben möglicherweise Zugang zu Ihren personenbezogenen Daten, soweit dies für die Bereitstellung solcher Dienste erforderlich ist.</p>
        <p>Darüber hinaus dürfen die COGITANDA Risk Prevention GmbH, die COGITANDA Claims Services GmbH, die COGITANDA Managed Services GmbH, die COGITANDA Insurance Services GmbH, die COGITANDA Austria GmbH und die COGITANDA France SAS Ihre personenbezogenen Daten an die Muttergesellschaft COGITANDA Dataprotect AG, für den Zweck der Unterstützung bei der Verarbeitung in der Betriebsführung, Management und Analyse der Kundenbeziehungen und Interaktionen und dazugehörige allgemeine IT-Betreuung übermitteln.</p>
        <p>Mit Eintritt der entsprechenden Datenübermittlungsvereinbarungen, die auf einer Auftragsverarbeitung basieren und durch Ergreifen von Maßnahmen zur Gewährleistung eines angemessenen Datenschutzniveaus, haben wir festgelegt, dass die Unternehmen der COGITANDA Gruppe ein angemessenes Datenschutzniveau liefern werden.</p>
        <p>Jeder Zugriff auf Ihre personenbezogenen Daten ist auf die Personen beschränkt, die die Information für die Vervollständigung Ihrer Tätigkeiten benötigen.</p>
        <p>Die Unternehmen der COGITANDA Gruppe dürfen Ihre personenbezogenen Daten offenlegen, sollte dies von Regierungsbehörden, Gerichten, externe Behörden oder auch ähnlichen Dritten gefordert oder verlangt werden.</p>

        <h2>Aufbewahrungsfrist:</h2>
        <p>Die personenbezogenen Daten werden solange wie erforderlich von der COGITANDA Gruppe und unseren Dienstleister gespeichert, bis unsere Verpflichtungen erfüllt sind. Die Daten werden so lange gesichert, wie dies für den Zweck erforderlich ist gemäß dem geltenden Datenschutzgesetz. Sobald die COGITANDA Gruppe die Daten nicht länger zur Erfüllung vertraglicher oder gesetzlicher Verpflichtungen benötigt, werden diese aus unseren Systemen und Aufzeichnungen entfernt und/oder Maßnahmen ergriffen, sodass Ihre personenbezogenen Daten ordnungsgemäß anonymisiert werden, sodass sie nicht mehr identifizierbar sind, außer wir müssen die personenbezogenen Daten von Ihnen zur Einhaltung gesetzlicher oder aufsichtsrechtlicher Verpflichtungen, denen die Unternehmen der COGITANDA Gruppe unterliegen, behalten. Z. B. gesetzlich vorgeschriebene Aufbewahrungsfristen, die sich aus Handelsgesetzbuch, Steuergesetzbuch ergeben und die sich meist zwischen 6 und 10 Jahre befinden oder wir müssen Beweise innerhalb der Verjährungsfrist sichern, die normalerweise 3 Jahre beträgt, aber auch bis zu 30 Jahren andauern kann.</p>
        
        <h2>Ihre Rechte:</h2>
        <p>Sollte Sie Ihr Einverständnis hinsichtlich bestimmter Verarbeitungstätigkeiten gegeben haben, können Sie diese jederzeit mit Wirkung auf die Zukunft widerrufen. Diese Widerrufung wird die vorherige Bearbeitung von Daten nicht beeinflussen.</p>
        <p>Gemäß den geltenden Datenschutzgesetzen haben Sie das Recht: a) Einsicht in Ihre personenbezogenen Daten zu nehmen; b) die Berichtigung Ihrer personenbezogenen Daten anzusetzen; c) die Löschung Ihrer personenbezogenen Daten zu beantragen; d) Einschränkungen bei der Verarbeitung Ihrer personenbezogenen Daten vorauszusetzen; e) die Übertragung Ihrer personenbezogenen Daten anzufordern; f) der Verarbeitung Ihrer personenbezogenen Daten zu wiedersprechen. Beachten Sie, dass die oben genannten Rechte durch nationales Recht beschränkt sein könnten.</p>

        <ul>
            <li>
                <u>Auskunftsrecht:</u> Sie haben das Recht, von uns eine Bestätigung zu erhalten, ob wir Ihre personenbezogenen Daten verarbeiten und auf diese Daten Zugriff erlangen. Die Zugriffsinformationen enthalten unter anderem den Zweck der Verarbeitung, die Kategorien der personenbezogenen Daten, die Betroffen und die Empfänger oder Kategorien der Empfänger. Dies ist jedoch kein absolutes Recht und die Interessen anderer Personen können Ihr Zugangsrecht einschränken.<br/>Sie haben das Recht, eine Kopie von den personenbezogenen Daten, die verarbeitet werden, zu erhalten.<br/>Für weitere Kopien, die von Ihnen angefordert werden, haben wir das Recht, eine angemessene Gebühr für die administrativen Kosten zu veranschlagen.
            </li>
            <li>
                <u>Recht auf Berichtigung:</u> Sie haben das Recht, von uns eine Berichtung von unrichtigen personenbezogenen Daten, die Sie betreffen, zu verlangen. Abhängig von dem Zweck der Verarbeitung haben Sie das Recht, unvollständige personenbezogenen Daten mittels einer Zusatzerklärung vervollständigt zubekommen.
            </li>
            <li>
                <u>Recht auf Löschung (Recht auf Vergessen):</u> Unter bestimmten Umständen haben Sie das Recht auf Löschung von personenbezogenen Daten, die Sie betreffen.
            </li>
            <li>
                <u>Recht auf eingeschränkte Verarbeitung:</u> Unter bestimmten Umständen haben Sie das Recht, von uns eine Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu erhalten. Unter diesen Umständen werden die entsprechenden Daten markiert und nur für einen bestimmten Zweck verarbeitet.
            </li>
            <li>
                <u>Recht auf Datenübertragung:</u> Unter bestimmten Umständen haben Sie das Recht, die von Ihnen zur Verfügung gestellten personenbezogenen Daten in einem strukturierten, häufig verwendeten und maschinenlesbaren Format zu erhalten und sind berechtigt, diese Daten ungehindert an ein anderes Unternehmen zu übermitteln.
            </li>
        </ul>

        <p>Um Ihre Rechte auszuüben, kontaktieren Sie uns wie in dem „Fragen“-Abschnitt genannt.</p>

        <p>Sie haben zusätzlich das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzulegen.</p>


        <h2>Widerspruchsrecht gemäß Art. 21 Datenschutzgrundverordnung</h2>
        <p>Die betroffene Person hat das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der sie betreffenden personenbezogenen Daten, die aufgrund von Artikel 6 Absatz 1 Buchstaben e oder f erfolgt, Widerspruch einzulegen.</p>
        <p>Da die Unternehmen der COGITANDA Gruppe Ihre personenbezogenen Daten primär für die Zwecke des Vertragsverhältnisses mit Ihnen verarbeiten und nutzen, hat die zur Gruppe gehörenden Unternehmen grundsätzlich ein berechtigtes Interesse, Ihre Daten zu verarbeiten was hingegen Ihren Einschränkungsantrag aufheben wird.</p>
        <p>Um dieses Recht auszuüben, kontaktieren Sie uns wie in dem “Fragen”-Abschnitt genannt.</p>


        <p>Die COGITANDA Dataprotect AG führt keine automatische Entscheidungsfindung durch.</p>

        <h2>Fragen:</h2>
        <p>Den Datenschutzbeauftragten der COGITANDA Dataprotect AG können Sie unter folgenden Kontaktdaten erreichen: <a href="mailto:privacy@cogitanda.com">privacy@cogitanda.com</a></p>
    </div>
</template>
<script>

export default {
}

</script>