<template></template>
<script>
export default {
    created() {
        const language = {
            title: 'Cyber Sofort Hilfe', 
            hotlinetitle: '365/24/7 NOTFALLHOTLINE', 
            hotlinelabel: 'Unsere Hotline mit unseren ausgewiesenen Cyber-Experten und IT-Forensikern steht rund um die Uhr für Sie zur Verfügung. ', 
            analysetitle: 'ANALYSE UND SOFORTMAßNAHMEN', 
            analyselabel: 'Spätestens 2 Stunden nach der Meldung des Notfalls beginnen wir mit der konkreten Analyse sowie der Einleitung von Sofortmaßnahmen.', 
            networktitle: 'EINSATZ VON NETZWERK-SPEZIALISTEN', 
            networklabel: 'Bei der COGITANDA<sup><small>®</small></sup> Cyber Sofort Hilfe ist in den ersten 48 Stunden, bis zu einer Gesamtsumme von 7.500 €, der Einsatz von Cyber-Spezialisten aus unserem Netzwerk inklusive.', 
            accesstitle: 'ZUGANG ZUM COGITANDA<sup><small>®</small></sup> EXPERTEN NETZWERK ',
            accesslabel: 'Durch Abschluss des Bausteins COGITANDA<sup><small>®</small></sup> Cyber Krisen Management stehen eine Vielzahl von Spezialisten, z.B. IT-Forensiker, IT-Security-Spezialisten, Juristen, Krisenmanager sowie Experten für Krisenkommunikation und Verhandlung in Erpressungssituationen, auf Abruf zur Verfügung.', 
            taxlabel: '* zuzüglich Mehrwertsteuer',
            price: "Preise"
        };
        this.$emit('passLanguage', language)
    }
}
</script>