<script setup>
import { ref } from "vue";
import Dialog from 'primevue/dialog';

const visible = ref(false);
</script>

<template>
    <component :is="selectedLanguage" @passLanguage="setLanguage" />
    <template v-if="contentType === 'imp'">
        <a :alt="locallanguage.imprint" :href="'#'+locallanguage.imprint" @click="visible = true">{{ locallanguage.imprint }}</a>
        <Dialog v-model:visible="visible" id="popuptool" modal :header="locallanguage.imprint">
            <Imprint />
        </Dialog>
    </template>
    <template v-if="contentType === 'dat'">
        <a  :alt="locallanguage.dataprotection" :href="'#'+locallanguage.dataprotection" @click="visible = true">{{ locallanguage.dataprotection }}</a>
        <Dialog v-model:visible="visible" id="popuptool" modal :header="locallanguage.dataprotection">
            <DataProtection />
        </Dialog>
    </template>
    <template v-if="contentType === 'dge'">
        <a :alt="locallanguage.dataprotectionbusiness" :href="'#'+locallanguage.dataprotectionbusiness" @click="visible = true">{{ locallanguage.dataprotectionbusiness }}</a>
        <Dialog v-model:visible="visible" id="popuptool" modal :header="locallanguage.dataprotectionbusinesstitle">
            <DataProtectionBusiness />
        </Dialog>
    </template>
</template>

<style src="../../../components/tools/popup/style.css"></style>

<script>

import { mapState } from 'vuex'; 

export default {
    props: {
        contentType: String,
    },
    data() {
        return {
            locallanguage: {
                imprint: '', dataprotection: '', dataprotectionbusiness:'' 
            }
        }
    },
    computed: {
        ...mapState(['imagePath',  'updateView', 'language']),
        selectedLanguage() {
            return this.language+'component';
        },
    },
    methods: {
        setLanguage(locallanguage) {
            this.locallanguage = locallanguage
        }
    },
    components: {    
        'Imprint': require('@/components/contents/imprint/content.vue').default,
        'DataProtection': require('@/components/contents/dataprotection/content.vue').default,
        'DataProtectionBusiness': require('@/components/contents/dataprotectionbusiness/content.vue').default,
        'DEcomponent': require('@/components/tools/popup/DE.vue').default,
        'FRcomponent': require('@/components/tools/popup/FR.vue').default
    }
}
</script>