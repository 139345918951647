<template>
  <div>
    <HeaderComponent @headerHeight="handleHeaderValue" />
    <TeaserComponent/>
    <thinkComponent/>
    <cyberhelpComponent/>
    <servicesComponent/>
    <cybercrisisComponent/>
    <crisisdeptComponent/>
    <formulaComponent/>
    <footerComponent/>
    <cookiesComponent/>
  </div>

</template>

<style src="../assets/css/fonts.css"></style>
<style src="../assets/css/style.css"></style>

<script>
export default {
  name: 'Home',
  data() {
      return {
          title: "Default Title",
          screenValues: {
            device: '',
            width: 0,
            height: 0,
            dimension: '',
            header: {
              height: 0
            }
          },
          currentEnvironment: {
            environment: 'test',
            language: 'DE'
          }
      }
  },
  metaInfo() {
      return {
          title: this.title,
          // ...
      };
  },
  mounted() {
    this.setScreenSize();
    window.addEventListener('resize', this.setScreenSize);

    const currentDomain = window.location.hostname;
    this.currentEnvironment.environment = ((currentDomain.includes("localhost") || currentDomain.includes(".testing.")))? 'test': 'prod';
    this.currentEnvironment.language = (currentDomain.includes('sofort-hilfe.de'))? 'DE' : this.currentEnvironment.language;
    this.currentEnvironment.language = (currentDomain.includes('urgence.fr'))? 'FR' : this.currentEnvironment.language;

    if(this.currentEnvironment.environment == 'test') {
      const currentParams = new URLSearchParams(window.location.search);
      this.currentEnvironment.language = (currentParams.get('lang'))? currentParams.get('lang').toUpperCase(): this.currentEnvironment.language; //TESTING ENVIRONMENT
    }

    this.$store.dispatch('updateEnvironment', this.currentEnvironment);

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setScreenSize);
  },
  methods: {
    setScreenSize() {
      this.screenValues.width = window.innerWidth;
      this.screenValues.height = window.innerHeight;
      this.screenValues.device = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))? 'mobile':'desktop';
      this.screenValues.dimension = (window.innerHeight > window.innerWidth)? 'yx': 'xy';
    },
    handleHeaderValue(value) {
      this.screenValues.header.height = value;
    }
  },
  components: {
    'HeaderComponent': require('@/components/parts/header/component.vue').default,
    'cookiesComponent': require('@/components/parts/cookies/component.vue').default,
    'TeaserComponent': require('@/components/parts/teaser/component.vue').default,
    'thinkComponent': require('@/components/parts/think/component.vue').default,
    'cyberhelpComponent': require('@/components/parts/cyberhelp/component.vue').default,
    'servicesComponent': require('@/components/parts/services/component.vue').default, 
    'cybercrisisComponent': require('@/components/parts/crisismanagement/component.vue').default,
    'crisisdeptComponent': require('@/components/parts/crisistable/component.vue').default,
    'formulaComponent': require('@/components/parts/formula/component.vue').default,
    'footerComponent': require('@/components/parts/footer/component.vue').default
  },
};

</script>