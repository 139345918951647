<template>
  <div id="crisismanagement" class="bkg_white">
    <component :is="selectedLanguage" @passLanguage="setLanguage" />
    <div class="content">
      <h2>{{ locallanguage.title }}</h2>
      <img class="desktop" :alt="locallanguage.title" :src="imagePath + locallanguage.image_desktop + '?' + updateView"/>
      <img class="mobile"  :alt="locallanguage.title"  :src="imagePath + locallanguage.image_mobile + '?' + updateView" width="452" height="381"/>
    </div>
  </div>
</template>

<style src="../../../components/parts/crisistable/style.css"></style>
    
<script>

import { mapState } from 'vuex'; 

export default {
  data() {
    return {
      locallanguage: {
        title: '', image_desktop: '', image_mobile: ''
      }
    }
  },
  computed: {
    ...mapState(['imagePath',  'updateView', 'language']),
    selectedLanguage() {
        return this.language+'component';
    },
  },
  methods: {
    setLanguage(locallanguage) {
      this.locallanguage = locallanguage
    }
  },
  components: {
    'DEcomponent': require('@/components/parts/crisistable/DE.vue').default,
    'FRcomponent': require('@/components/parts/crisistable/FR.vue').default
  }
}
</script>