<template>
  <div>
    <HeaderComponent @headerHeight="handleHeaderValue" />
    <div id="cyberfacts">
      <div class="localTeaser">
        <img alt="cyberfacts" class="imgdesktop" :src="imagePath + 'cyberfacts/cyberfacts.jpg?'"/>
        <img alt="cyberfacts" class="imgmobile" :src="imagePath + 'cyberfacts/cyberfacts_mobile.jpg?'"/>
        <div class="titleframelocal">
          <div class="framelocalcontent">
            <div class="left-div">
            </div>
            <div class="right-div">

            </div>
            <div class="middle-div">
              <h1>
                CYBER FACTS
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="gridContent">
        <div class="row">
            <h5>Was will ein Hacker?</h5>
            <p>
              Den einen, typischen Hacker gibt es nicht. Es klingt vielleicht verwunderlich, aber manche Hacker haben sogar gute Absichten. Im Jargon nennt man sie White-Hat-Hacker oder auch Ethical Hacker. Ziel ihrer Cyberattacke ist es, Sicherheitslücken auszumachen, um einem System später mehr Abwehrkräfte geben zu können. Bei einer kriminellen Cyberattacke eines sogenannten Black-Hat-Hackers geht es darum, einem Unternehmen Daten zu stehlen und Schaden anzurichten. Eine dritte Kategorie sind die Grey-Hat-Hacker. Sie verüben den Cyberangriff zwar illegal, aber ohne kriminelle Absichten. Ihr Ziel ist es, auf unbekannte oder ignorierte Sicherheitsprobleme im Unternehmen aufmerksam zu machen.
            </p>
            <p>
              Bei einer kriminellen Cyberattacke geht es in aller Regel um Geld. Die Hacker verschlüsseln die Daten der Organisation und verlangen für das Entschlüsseln ein Lösegeld. Zahlt das Unternehmen das Lösegeld nicht, kommt es nicht mehr an seine Daten und/oder die Erpresser veröffentlichen die Daten geschäftsschädigend im Internet.
            </p>
            <h5>Für wen interessiert sich ein Hacker?</h5>
            <p>
              Im Grunde für jeden, der sich als leichtes Opfer präsentiert. Die Idee, dass nur Konzerne, Krankenhäuser, Politiker oder VIPs von Cyberattacken betroffen sind, ist irreführend. Medial wird eine solche Cyberattacke oft mehr ausgeschlachtet, repräsentativ ist es aber nicht. Auch kleine und mittelständische Unternehmen sind für Hacker attraktiv. Gerade Firmen, die davon ausgehen, dass sie nicht ins Fadenkreuz eines Cyberangriffs kommen, sind für Black-Hat-Hacker ein gefundenes Fressen. Der Grund: Diese Unternehmen versäumen es häufig, ihre Infrastruktur angemessen zu schützen.
            </p>
            <h5>Wie kommt ein Hacker in mein System?</h5>
            <p>
              Die meisten Hackerangriffe erfolgen über Malware, die die Computer der Opfer infiziert. In der Regel geschieht dies über bösartige Links, manipu- lierte Anhänge oder gefälschte Websites. Cyberkriminelle wissen aber auch, wie sich Computersysteme auf direktere Weise hacken lassen. Unternehmen, die keinen Firewall-Schutz haben, oder schwache und damit für Passwort-Cracking-Techniken anfällige Passwörter verwenden, werden schnell zur Zielscheibe.
            </p>
            <h5>Woran erkennt man Ransomware?</h5>
            <p>
              Ransomware sind schädliche Programme, die in Deutschland auch unter den Bezeichnungen Erpressungstrojaner, Erpressungssoftware oder Verschlüsselungstrojaner bekannt sind. Eine Cyberattacke mit diesen Schadprogrammen verhindert den Zugriff des Computerinhabers auf sein eigenes Computersystem. Typische Anzeichen für eine Cyberattacke mit
            </p>

            <p class="p-3">
              Ransomware sind plötzliche Dateiverschlüsselungen, Warnmeldungen, Veränderungen am Desktophintergrund oder auch eine langsamere Systemleistung. Ist ihr Rechner von Ransomware befallen, ist es wichtig, nicht auf die Forderungen einzugehen und sofort professionelle Hilfe anzufordern. Ransomware-Angriffe können schwerwiegende Folgen haben und das Bezahlen des Lösegeldes garantiert leider nicht, dass man den Zugriff auf seine Daten zurückbekommt.
            </p>
            <h5 class="p-3">Schützt mein Antivirus-Programm vor Hackern?</h5>
            <p class="p-3">
              Ein Antivirus-Programm ist darauf ausgerichtet, Schutz vor Viren, Trojanern, Würmern und anderen Arten von Malware zu bieten. Es kann auch vor einigen Arten von Ransomware schützen. Allerdings ist ein Antivirus-Programm allein nicht ausreichend, um vollständigen Schutz vor Hackern zu gewährleisten, denn eine Cyberattacke findet oft auf unterschiedlichen Ebenen statt. 
            </p>
            <h5 class="p-3">Welche zusätzlichen Sicherheitsmaßnahmen sollte ich ergreifen?</h5>

            <div class="side-listing p-3">
              Um sein System vor einer Cyberattacke zu schützen, kann man folgende, zusätzliche Maßnahmen ergreifen:
              <ol>
                <li>
                  <span class="list-number">1.</span> Sie eine Firewall auf allen Clients und Servern, um den Datenverkehr zu überwachen und unautorisierten Zugriff zu blockieren.
                </li>
                <li>
                  <span class="list-number">2.</span>Halten Sie das Betriebssystem, die Anwendungen und das Antivirus-Programm auf dem neuesten Stand, um bekannte Sicherheitslücken zu schließen.
              </li>
              </ol>
            </div>
        </div>

        <div class="row">
          <p>
            Ransomware sind plötzliche Dateiverschlüsselungen, Warnmeldungen, Veränderungen am Desktophintergrund oder auch eine langsamere Systemleistung. Ist ihr Rechner von Ransomware befallen, ist es wichtig, nicht auf die Forderungen einzugehen und sofort professionelle Hilfe anzufordern. Ransomware-Angriffe können schwerwiegende Folgen haben und das Bezahlen des Lösegeldes garantiert leider nicht, dass man den Zugriff auf seine Daten zurückbekommt.
          </p>
          <h5>Schützt mein Antivirus-Programm vor Hackern?</h5>
          <p>
            Ein Antivirus-Programm ist darauf ausgerichtet, Schutz vor Viren, Trojanern, Würmern und anderen Arten von Malware zu bieten. Es kann auch vor einigen Arten von Ransomware schützen. Allerdings ist ein Antivirus-Programm allein nicht ausreichend, um vollständigen Schutz vor Hackern zu gewährleisten, denn eine Cyberattacke findet oft auf unterschiedlichen Ebenen statt. 
          </p>
          <h5>Welche zusätzlichen Sicherheitsmaßnahmen sollte ich ergreifen?</h5>
          <div class="middle-listing">
            Um sein System vor einer Cyberattacke zu schützen, kann man folgende, zusätzliche Maßnahmen ergreifen:
            <ol>
              <li>
                Aktivieren Sie eine Firewall auf allen Clients und Servern, um den Datenverkehr zu überwachen und unautorisierten Zugriff zu blockieren.
              </li>
              <li>
                Halten Sie das Betriebssystem, die Anwendungen und das Antivirus-Programm auf dem neuesten Stand, um bekannte Sicherheitslücken zu schließen.
              </li>
              <li>
                Verwenden Sie komplexe und einzigartige Passwörter für verschiedene Konten, um unbefugten Zugriff zu erschweren.
              </li>
              <li>
                Aktivieren Sie wenn möglich überall die Zwei-Faktor-Authentifizierung (2FA), um eine zusätzliche Sicherheitsebene hinzuzufügen.
              </li>
              <li>
                Seien Sie vorsichtig beim Öffnen von E-Mails von unbekannten Absendern und klicken Sie nicht auf verdächtige Links oder Anhänge.
              </li>
              <li>
                Erstellen Sie regelmäßige physisch getrennte Backups der wichtigen Daten und Systeme, damit Sie diese im Falle einer Ransomware-Infektion wiederherstellen können. Bewahren Sie Ihre Backups sicher und offline auf.
              </li>
            </ol>
          </div>

          <p>
            Es ist sinnvoll, verschiedene Sicherheitsmaßnahmen zu kombinieren, um einen umfassenden Schutz vor Hackern zu gewährleisten.
          </p>
          <h5>Was tun bei einer Cyberattacke?</h5>
          <p>
            Bei einer Cyberattacke ist es essenziell, besonnen, aber rasch zu handeln. Trennen Sie alle Geräte vom Netzwerk, aber fahren Sie den Computer nicht herunter. Aktualisieren Sie die Passwörter und machen Sie 
          </p>
        </div>

        <div class="row">

          <div class="side-listing p-3">
            <ol>
              <li>
                <span class="list-number">3.</span>Verwenden Sie komplexe und einzigartige Passwörter für verschiedene Konten, um unbefugten Zugriff zu erschweren.
              </li>
              <li>
                <span class="list-number">4.</span>Aktivieren Sie wenn möglich überall die Zwei-Faktor-Authentifizierung (2FA), um eine zusätzliche Sicherheitsebene hinzuzufügen.
              </li>
              <li>
                <span class="list-number">5.</span>Seien Sie vorsichtig beim Öffnen von E-Mails von unbekannten Absendern und klicken Sie nicht auf verdächtige Links oder Anhänge.
              </li>
              <li>
                <span class="list-number">6.</span>Erstellen Sie regelmäßige physisch getrennte Backups der wichtigen Daten und Systeme, damit Sie diese im Falle einer Ransomware-Infektion wiederherstellen können. Bewahren Sie Ihre Backups sicher und offline auf.
              </li>
            </ol>
          </div>

          <p class="p-3">
            Es ist sinnvoll, verschiedene Sicherheitsmaßnahmen zu kombinieren, um einen umfassenden Schutz vor Hackern zu gewährleisten.
          </p>
          <h5 class="p-3">Was tun bei einer Cyberattacke?</h5>
          <p class="p-3">
            Bei einer Cyberattacke ist es essenziell, besonnen, aber rasch zu handeln. Trennen Sie alle Geräte vom Netzwerk, aber fahren Sie den Computer nicht herunter. Aktualisieren Sie die Passwörter und machen Sie 
          </p>

          <p>
            Passwörter und machen Sie Sicherheitsupdates. Versuchen Sie, alle Beweise zu sichern und betroffene Personen zu informieren. Bei einer Cyberattacke gilt: Je schneller Maßnahmen ergriffen werden, desto besser wird das System vor potenziellen Schäden geschützt.
          </p>
          <h5>Muss ich die Polizei über einen Cyberangriff informieren?</h5>
          <p>
            Sollten Sie merken, dass Sie auf eine Phishing-Mail hereingefallen sind oder ihr Rechner mit einem Trojaner verseucht ist, raten wir unbedingt zur Anzeige bei der nächsten Polizeidienststelle. Fragen Sie dort nach den zentralen Ansprechstellen für Cybercrime.  Zusätzlich sollte auch das interne IT-Sicherheitsteam und/oder die IT-Abteilung über den Vorfall informiert werden. 
          </p>
          <h5>Mache ich mich strafbar, wenn ich im Darknet mit einem Hacker kommuniziere?</h5>
          <p>
            Das Bewegen im Darknet ist nicht illegal, das Sicherheitsrisiko ist für Unerfahrene allerdings hoch. Straffällig werden Sie in dem Moment, in dem Sie rechtswidrige Waren oder Dienstleistungen erwerben, illegale Inhalte konsumieren, verbreiten oder herunterladen. Es ist also insgesamt nicht empfehlenswert, mit einem Hacker im Darknet zu kommunizieren. 
          </p>
          <h5>Sollte ich auf einen Link bei einer Cyberattacke klicken? Mache ich mich damit strafbar?</h5>
          <p>
            Auf einen Link zu klicken, der im Zusammenhang mit einer Cyberattacke steht, ist nie empfehlenswert. Das Klicken auf verdächtige Links kann nämlich zu weiteren Schäden führen. So kann schädliche Software unwillentlich heruntergeladen werden oder es erfolgt eine Weiterleitung zu gefälschten Websites, die darauf abzielen, persönliche Informationen zu stehlen. 
          </p>
          <p>
            Wenn Sie Opfer einer Hackerattacke oder eines Phishing-Versuchs geworden sind, ignorieren Sie solche Links bitte und sprechen Sie mit einem Profi. Verhandlungen mit Erpressern sollten nicht auf die leichte Schulter genommen werden. Bevor Sie in die Kommunikation eintreten, sollten Sie sich bewusstwerden, ob Sie das Lösegeld überhaupt zahlen wollen und können und was dies für Folgen hätte. Eine Begleitung durch Experten ist dringlich zu empfehlen! 
          </p>
          <h5>Wie bezahlt man Lösegeld an einen Hacker?</h5>
          <p>
            Vorweg der Hinweis, dass es in jedem Fall empfehlenswert ist, Lösegeldforderungen mit einem Cybersecurity-Profi zu besprechen. In der Regel werden Lösegelder im Zusammenhang mit Cyberattacken in Bitcoin oder anderen Kryptowährungen bezahlt. Wenn Sie noch keine Erfahrung im Umgang mit Kryptowährungen haben, sollten Sie sich auch hier einen Experten suchen. Angemerkt sei auch, dass nicht jede Bank die Zahlung von Lösegeld unterstützt.
          </p>
        </div> 
      </div>
    </div>
    <footerComponent/>
    <cookiesComponent/>
  </div>

</template>

<style src="../assets/css/fonts.css"></style>
<style src="../assets/css/style.css"></style>
<style src="../components/cyberfacts.css"></style>

<script>
import { mapState } from 'vuex'; 

export default {
  name: 'Home',
  data() {
      return {
          title: "Default Title",
          screenValues: {
            device: '',
            width: 0,
            height: 0,
            dimension: '',
            header: {
              height: 0
            }
          },
          currentEnvironment: {
            environment: 'test',
            language: 'DE'
          }
      }
  },
  computed: {
    ...mapState(['imagePath',  'updateView', 'language']),
  },
  metaInfo() {
      return {
          title: this.title,
          // ...
      };
  },
  mounted() {
    this.setScreenSize();
    window.addEventListener('resize', this.setScreenSize);

    const currentDomain = window.location.hostname;
    this.currentEnvironment.environment = ((currentDomain.includes("localhost") || currentDomain.includes(".testing.")))? 'test': 'prod';
    this.currentEnvironment.language = (currentDomain.includes('sofort-hilfe.de'))? 'DE' : this.currentEnvironment.language;
    this.currentEnvironment.language = (currentDomain.includes('urgence.fr'))? 'FR' : this.currentEnvironment.language;

    if(this.currentEnvironment.environment == 'test') {
      const currentParams = new URLSearchParams(window.location.search);
      this.currentEnvironment.language = (currentParams.get('lang'))? currentParams.get('lang').toUpperCase(): this.currentEnvironment.language; //TESTING ENVIRONMENT
    }

    this.$store.dispatch('updateEnvironment', this.currentEnvironment);

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setScreenSize);
  },
  methods: {
    setScreenSize() {
      this.screenValues.width = window.innerWidth;
      this.screenValues.height = window.innerHeight;
      this.screenValues.device = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))? 'mobile':'desktop';
      this.screenValues.dimension = (window.innerHeight > window.innerWidth)? 'yx': 'xy';
    },
    handleHeaderValue(value) {
      this.screenValues.header.height = value;
    }
  },
  components: {
    'HeaderComponent': require('@/components/parts/header/component.vue').default,
    'footerComponent': require('@/components/parts/footer/component.vue').default,
    'cookiesComponent': require('@/components/parts/cookies/component.vue').default
  },
};

</script>