<template>
    <div class="inner lightgraybkg">
        <div class="d-floatleft">
            <div class="d-content form" id="step03">
            <div class="title">
                ANSPRECHPARTNER
            </div>

            <div class="p-width100">
                <select 
                    v-model="stepData.contacttitle"
                    class="p-inputtext p-component reqsubmit">
                    <option value="Frau">Frau</option>
                    <option value="Herr">Herr</option>
                    <option value="keine Angabe">bitte auswählen</option>
                </select>
                <span>Anrede *</span>
            </div>

            
            <div class="p-width100">
                <input 
                v-model="stepData.contactfirstname"
                id="contactfirstname"  
                type="text" 
                class="p-inputtext p-component reqsubmit"
                />
                <span>Vorname *</span>
            </div>

            <div class="p-width100">
                <input 
                v-model="stepData.contactfamilyname"
                id="contactfamilyname" 
                type="text" 
                class="p-inputtext p-component reqsubmit"
                />
                <span>Name *</span>
            </div>

            <div class="p-width100" >
                <input 
                    v-model="stepData.companyemail"
                    id="companyemail" 
                    type="email" 
                    class="p-inputtext p-component reqsubmit"
                    />
                <span>E-Mail (geschäftlich) *</span>
            </div>

            <div class="p-width100 p-margin15B">
                <input 
                    v-model="stepData.companymobile"
                    id="companymobile"  
                    type="text"
                    class="p-inputtext p-component reqsubmit"
                    />
                    <span>Mobil (geschäftlich) *</span>
            </div>
            <div class="p-width100">
                <input 
                    v-model="stepData.contactemail"
                    id="contactemail"  
                    type="text"
                    class="p-inputtext p-component reqsubmit"
                    />
                <span>E-Mail (privat – Notfälle) * <label class="circle" @click="infoText()">i</label></span>
            </div>

            <div class="p-width100">
                <input 
                    v-model="stepData.contactmobile"
                    id="contactmobile"  
                    type="text"
                    class="p-inputtext p-component reqsubmit"
                    />
                <span>Mobil (privat – Notfälle) * <label class="circle" @click="infoText()">i</label></span>
            </div>
        </div>
    </div>
    <div class="d-floatright">
        <div class="d-content form" id="formBoard">
            <div class="divscrolly">
                <div class="tab-error" v-if="infoTextView">
                    <u>Informationstext:</u> Es ist essenziell wichtig, eine private Telefonnummer und E- Mail Adresse bei Cyberattacken bereitszuhalten. In bestimmten Situationen kann es zu Ausfällen der Telefonanlage kommen oder der Zugriff auf E-Mail Postfächer innerhalb des Unternehmens verwehrt werden. Eine direkte Kontaktmöglichkeit ist entscheidend, um schnell auf Sicherheitsvorfälle reagieren zu können.
                </div>
                <div class="tab-error" v-if="stepData.stepPass==false">
                    Bitte füllen Sie alle Pflichtfelder aus, um fortzufahren.
                </div>
                <div>&nbsp;</div>
            </div>
        </div>
    </div>
    <div class="d-floatclear form" style="padding: 0!important; height: 1px!important">&nbsp;</div>
</div>  
</template>
  
<style src="../../../../components/tools/contractform/DE/step03.css"></style>
  
<script>

import { mapState, mapActions } from 'vuex'; 

export default {
    data() {
        return {
            stepName: 'step03',
            infoTextView: false,
            stepData: {
                contacttitle: 'keine Angabe',
                contactfirstname: '',
                contactfamilyname: '',
                companyemail: '',
                companymobile: '',
                contactemail: '',
                contactmobile: '',
            }
        }
    },
    computed: {
        ...mapState(['imagePath', 'updateView', 'country', 'contractSteps']),
    },
    watch: {
        'contractSteps': {
            handler(newdata) {
                this.stepData = newdata.step03;
            }
        }
    },
    methods: {
        ...mapActions(['updateContractSteps']),
        changeContractSteps(newContractSteps) {
            this.$store.dispatch('updateContractSteps', newContractSteps);
        },
        infoText() {
            this.infoTextView = !this.infoTextView;
        }
    }, 
    mounted() {
        if(!this.contractSteps.hasOwnProperty(this.stepName)){

            this.stepData = {...this.stepData};
            this.changeContractSteps({step03: this.stepData});

        } else {
            this.stepData = this.contractSteps.step03;
        }
    }
}
</script>