<script setup>  

import axios from "axios";
import Dialog from 'primevue/dialog';
import '@vuepic/vue-datepicker/dist/main.css';

</script>

<template>
  <div>
    <div class="card flex justify-content-center">
        <img id="buttonpdf" class="button" alt="JETZT ABSCHLIEßEN" :src="imagePath + 'buttons/makecontract_DE.png?'+updateView" @click="handleView"/>
        <Dialog v-model:visible="visible" modal header="COGITANDA® Cyber Sofort Hilfe" :style="{ width: '50vw' }" id="formpdf" ref="popupFrame" @update:visible="handleClose">
            <h2 id="headersub">
              DIENSTLEISTUNGSVERTRAG            
            </h2>
            <div  class="framescroll noscroll de_framescroll"
                 :class="{ destroyview:isFormSucceeded === true }"
                 :style="{ overflowY: selectedStep === 5? 'hidden':'auto' }"
                 id="framescroll" ref="framescroll">
              <div id="fsheight">
                <form @submit.prevent="addNewUser" ref="newUserForm">
                    <div class="covertextleft" v-if="selectedStep!==7">
                      <label>Angaben zum Unternehmen</label>
                    </div>
                      <step01 v-if="selectedStep===1" @visibleClose="handleVisibleClose"/>
                      <step02 v-else-if="selectedStep===2"/>
                      <step03 v-else-if="selectedStep===3"/>
                      <step04 v-else-if="selectedStep===4"/>
                      <step05 v-else-if="selectedStep===5 || selectedStep===6" @stepNumber="editStep"/>
                      <step06 v-else-if="selectedStep===7"/>
                      <step07 v-else-if="selectedStep===8"/>
                </form>
              </div>
            </div>
            <template #footer>
              <div class="p-dialog-content" id="contractFooter">
                <!-- CONTRACT STEP NAVIGATOR -->
                <div class="stepsFrame" v-if="selectedStep < 5">
                  <img class="back" :src="imagePath + 'contractform/back.'+ ((selectedStep==1)? 'disabled':'enabled') +'.png?'+updateView" @click="stepNavigation('back', null)" />
                  <div class="stepsPoint">
                    <img :src="imagePath + 'contractform/step.'+ ((selectedStep===1)? 'blue':'gray') +'.png?'+updateView" @click="stepNavigation('step', 1)" />
                    <img :src="imagePath + 'contractform/step.'+ ((selectedStep===2)? 'blue':'gray') +'.png?'+updateView" @click="stepNavigation('step', 2)" />
                    <img :src="imagePath + 'contractform/step.'+ ((selectedStep===3)? 'blue':'gray') +'.png?'+updateView" @click="stepNavigation('step', 3)" />
                    <img :src="imagePath + 'contractform/step.'+ ((selectedStep===4)? 'blue':'gray') +'.png?'+updateView" @click="stepNavigation('step', 4)" />
                  </div>
                  <img class="next" :src="imagePath + 'contractform/continue.'+ ((selectedStep==5)? 'disabled':'enabled') +'.png?'+updateView" @click="stepNavigation('next', null)" />
                </div>
                <!-- BUTTON SEND CONTRACT-->
                <div class="stepsFrame" v-if="selectedStep===5">
                  <img 
                        id="buttonpdf" v-if="!contractOpen"
                        :disabled="isSendSucceeded" 
                        class="button" 
                        :src="(!buttonStatus) ? imagePath + 'buttons/signcontract_DE_error.png?'+updateView: imagePath + 'buttons/signcontract_DE_ready.png?'+updateView" @click="sendContract()"/>
                </div>

                <!-- BUTTON SEND CONTRACT: ACCEPTED -->
                <div class="stepsFrame" v-if="selectedStep===6">
                  <div class="stepsFinal">
                      <img :src="imagePath + 'contractform/progress.gif?'+updateView"/> BITTE WARTEN...
                  </div>
                </div>
              </div>
            </template>

        </Dialog>
    </div>
  </div>
</template>
    
<style src="../../../../components/tools/contractform/DE/style.css"></style>
<style src="../../../../components/tools/contractform/DE/footer.css"></style>
<script>
  
import { ref } from "vue";
import { mapActions, mapState } from 'vuex'; 

const visible = ref(false);

export default {
  name: "App",
  components: {    
        'contractInformation': require('@/components/contents/contract/content.vue').default,
        'step01': require('@/components/tools/contractform/DE/step01.vue').default,
        'step02': require('@/components/tools/contractform/DE/step02.vue').default,
        'step03': require('@/components/tools/contractform/DE/step03.vue').default,
        'step04': require('@/components/tools/contractform/DE/step04.vue').default,
        'step05': require('@/components/tools/contractform/DE/step05.vue').default,
        'step06': require('@/components/tools/contractform/DE/step06.vue').default,
        'step07': require('@/components/tools/contractform/DE/step07.vue').default 
  },
  data() {
    return {

      countdown: 300,
      timer: null,
      ontimer: false,

      stepName: 'step05',
      stepData: {
          selectedStep: false,
          contractconfirmation: false,
          contractConfirmationError: false,
          contractread: false,
          contractreadError: false,
          contractaccept: false,
          contractacceptError: false,
          sendcontract: false,
          editButton: true
      },

      steps: {1:false, 2:false, 3:false, 4: false},
      completeSteps: {},
      analytics: [],
      navigateStep: 0,
      //selectedStep: 1,
      isSendSucceeded: false,
      isFormSucceeded: false,
      buttonStatus: false,
      contractOpen: true

    }
  },
  computed: {
    ...mapState(['imagePath', 'updateView', 'country', 'contractSteps', 'currentEnvironment', 'selectedStep', 'cookies', 'gtag']),
  },
  watch: {
    'contractSteps': {
        deep: true,
        handler(newdata) {
            this.completeSteps = newdata;
            if(this.completeSteps.hasOwnProperty(this.stepName)) {
              this.buttonStatus = (this.completeSteps.step05.contractaccept===true && this.completeSteps.step05.contractread===true && this.completeSteps.step05.contractconfirmation===true)? true:false;
              this.contractOpen = this.completeSteps.step05.contractView;
            } else {
              selectedStep = 1;
            }
        }
    }
  },
  mounted() {
    if(!this.contractSteps.hasOwnProperty(this.stepName)){

        this.stepData = {...this.stepData};
        this.changeContractSteps({step05: this.stepData});

    } else {
        this.stepData = this.contractSteps.step05;
    }
    if(localStorage.getItem('contractform.refill')) {
      visible.value = true;
      document.removeEventListener('mousemove', this.resetTimer);
      document.removeEventListener('keydown', this.resetTimer);
      localStorage.removeItem('contractform.refill');
      if(this.currentEnvironment === 'test') {
        this.countdown = 300;
      }
      this.ontimer = true;
    }
    this.clearTimer();
    this.startTimer();
  },
  beforeDestroy() {
    this.clearTimer();
  },
  deactivated() {
    this.clearTimer();
  },
  methods: {
    ...mapActions(['clearAllSteps']),
    handleView() {

      visible.value = true;
      this.contractOpen = false;
      
      if(!this.ontimer) {
        this.resetTimer();
        this.ontimer = true;
      }
    },
    handleVisibleClose() {
      visible.value = false;
      window.location.reload();
    },
    startTimer() {
      this.clearTimer();
      this.clearTimer();

      if(this.currentEnvironment === 'test') {
        this.countdown = 3000;
      }
      this.timer = setInterval(() => {

          if (this.countdown > 0) {
              if(this.ontimer && this.selectedStep !== 7) {
                this.countdown--;
                if(this.currentEnvironment === 'test') {
                  console.log('timer: '+visible.value, this.countdown);
                }
              }
              if(visible.value===true) {
                document.addEventListener('mousemove', this.resetTimer);
                document.addEventListener('keydown', this.resetTimer);
              } else {
                document.removeEventListener('mousemove', this.resetTimer);
                document.removeEventListener('keydown', this.resetTimer);
              }
          } else {
              this.commitUpdateSelectedSteps(8);
              this.trackFormSteps('stepTIMEOUT');
              this.ontimer = false;
              clearInterval(this.timer);
              document.removeEventListener('mousemove', this.resetTimer);
              document.removeEventListener('keydown', this.resetTimer);
          }
      }, 1000);
    },
    clearTimer() {
      if(this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    resetTimer() {
        this.countdown = 300;
        if(this.currentEnvironment === 'test') {
          this.countdown = 3000;
        }
    },
    handleClose() {
        window.location.reload();
    },
    commitUpdateSelectedSteps(newValue) {
      this.$store.commit('updateSelectedStepState', newValue);
    },
    changeContractSteps(newContractSteps) {
      this.$store.dispatch('updateContractSteps', newContractSteps);
    },
    handleTimeOut(value) {
      this.commitUpdateSelectedSteps(value);
    },
    editStep(number) {
      this.navigateStep = (number * 1);
      this.commitUpdateSelectedSteps(this.navigateStep);
    },
    formatDate(vdate) {
      const nDate = (vdate!=='')? vdate.toLocaleDateString('de-DE'):'';
      const dateParts =  nDate.split('.');
      const formattedMonth = (dateParts[1].length === 1) ? '0' + dateParts[1] : dateParts[1];
      return `${dateParts[0]}/${formattedMonth}/${dateParts[2]}`;
    },
    delayLocal(ms, callback) {
      setTimeout(callback, ms);
    },
    stepNavigation(option, number) {

      let stepName = 'step01'; 
      let stepData = {};
      let stepPass = true;
      let stepNode = {};
      let stepNext = true;

      this.navigateStep = number;
      if(option == 'next') this.navigateStep = this.selectedStep + 1;
      if(option == 'back') this.navigateStep = this.selectedStep - 1;

      if(this.navigateStep > this.selectedStep) {

        switch(this.selectedStep) {
          case 1:

              stepName = 'step01'; 
              stepData = {};
              stepPass = true;
              stepNode = (this.contractSteps.hasOwnProperty(stepName)) ? this.contractSteps.step01: stepData;
              if(stepNode){
                stepPass = (
                            ((stepNode.hasOwnProperty('companyselection') && stepNode.companyselection !== 'default')) && 
                            ((stepNode.hasOwnProperty('companyannualsales') && stepNode.companyannualsales > 0 )) &&
                            ((stepNode.hasOwnProperty('companyfrom') && stepNode.companyfrom !== "")) &&
                            ((stepNode.hasOwnProperty('companyto') && stepNode.companyto !== ""))
                           )? true:false;
                
                stepData = {...stepNode, stepPass: stepPass };
                this.changeContractSteps({step01: stepData});
                
                stepNext = (stepNode.pass === true && stepPass === true)? true: false;

                //steps validation
                this.steps = { ...this.steps, 1: stepNext }

              }

          break;
          case 2:

              stepName = 'step02'; 
              stepData = {};
              stepPass = true;
              stepNode = (this.contractSteps.hasOwnProperty(stepName)) ? this.contractSteps.step02: stepData;
              if(stepNode) {
                for (const key in stepNode) {
                  if(key !== 'stepPass') {
                      if (stepNode[key] == '' && stepNode[key] == 0) {
                      stepPass = false;
                    }
                  }
                }
              } 

              stepData = {...stepNode, stepPass: stepPass};
              this.changeContractSteps({step02: stepData});
              
              stepNext = (stepPass === true)? true: false;

              //steps validation
              this.steps = { ...this.steps, 2: stepNext }

          break;
          case 3:

              stepName = 'step03'; 
              stepData = {};
              stepPass = true;
              stepNode = (this.contractSteps.hasOwnProperty(stepName)) ? this.contractSteps.step03: stepData;
              if(stepNode){
                for (const key in stepNode) {
                  if(key !== 'stepPass') {
                      if (stepNode[key] == '' && stepNode[key] == 0) {
                      stepPass = false;
                    }
                  }
                }
              } 

              stepData = {...stepNode, stepPass: stepPass};
              this.changeContractSteps({step03: stepData});

              stepNext = (stepPass === true)? true: false;

              //steps validation
              this.steps = { ...this.steps, 3: stepNext }

          break;
          case 4:

              stepName = 'step04'; 
              stepData = {};
              stepPass = true;
              stepNode = (this.contractSteps.hasOwnProperty(stepName)) ? this.contractSteps.step04: stepData;
              if(stepNode){
                if(stepNode.partnermediator === true) {
                  let pay = (
                              (
                                ((stepNode.partnermediator===true && stepNode.partnername !== "")) && 
                                ((stepNode.partnermediator===true && stepNode.partnernumber !== "")) &&
                                ((stepNode.partnermediator===true && stepNode.partnerdate !== ""))
                              ) || (
                                stepNode.partnermediator===false
                              )
                            )? true:false;
                      
                  let agt = (
                              (
                                ((stepNode.paymentmethod==="LASTSCHRIFT" && stepNode.paymentiban !== "")) &&
                                ((stepNode.paymentmethod==="LASTSCHRIFT" && stepNode.paymentbic !== ""))
                              )  || (
                                stepNode.paymentmethod==="RECHNUNG"
                              )
                            )? true:false;
                            
                  stepPass = (
                              agt && pay
                            )? true:false;
                } else {
                  stepPass = true;
                }
              } 

              stepData = {...stepNode, stepPass: stepPass};
              this.changeContractSteps({step04: stepData});

              stepNext = (stepPass === true)? true: false;

              //steps validation
              this.steps = { ...this.steps, 4: stepNext }
              
          break; 
        }
      }

      switch(option) {
        case 'step':
          if(!stepNext && (this.selectedStep < this.navigateStep)) return;

          if(number===3) {
            if(!(this.steps[1]===true && this.steps[2]===true)) return; 
          }
          if(number===4) {
            if(!(this.steps[1]===true && this.steps[2]===true && this.steps[3]===true)) return; 
          }

          this.commitUpdateSelectedSteps(number);
          this.scrollToTop();
        break;
        case 'next':
          if(!stepNext) return;
          if(this.selectedStep == 5 ) return;
          this.commitUpdateSelectedSteps(this.selectedStep + 1);
          this.scrollToTop();
        break;
        case 'back':
          if(this.selectedStep == 1 ) return;
          this.commitUpdateSelectedSteps(this.selectedStep - 1);
          this.scrollToTop();
        break;
      }
      
      this.trackFormSteps(stepName);

    },
    scrollToTop() {
      const scrDiv = this.$refs.framescroll;
      if (scrDiv) {
        scrDiv.scrollTop = 0;
      }
    },
    trackFormSteps(trackName) {

      if(!this.cookies.status) return; // IF cookies is not accepted

      let trackTitle = '';
      let trackValue = 0;

      switch(trackName) {
        case 'step01':
          trackTitle = 'CALCULATION OF THE ANNUAL FEE';
          trackValue = 16.66;
        break;
        case 'step02':
          trackTitle = 'COMPANY INFORMATION / BILLING ADDRESS';
          trackValue = 33.32;
        break;
        case 'step03':
          trackTitle = 'CONTACT PERSON';
          trackValue = 49.98;
        break;
        case 'step04':
          trackTitle = 'PAYMENT METHOD / INTERMEDIARY';
          trackValue = 66.64;
        break;
        case 'stepTIMEOUT':
          trackTitle = '05 MINUTEN TIMEOUT';
          trackValue = 0;
        break;
        case 'stepOVERVIEW':
          trackTitle = 'OVERVIEW/CONFIRMATION OF CONTRACT';
          trackValue = 83.34;
        break;
        case 'stepTHANKYOU':
          trackTitle = 'CONTRACT SUCCESSFUL / THANK YOU PAGE';
          trackValue = 100.00;
        break;
      }

      const exists = this.analytics.some(track => track.name === trackName);

      if(!exists) {
        this.analytics.push({ name: trackName });
      
       // let trackingId = (this.currentEnvironment === 'test')? this.gtag.TEST:(this.country==='FR')? this.gtag.FR: this.gtag.DE;

       // initGTM(trackingId);
        
        gtag('event', `${this.country} ${trackName} - ${trackTitle}`, {
          'event_category': 'Contract',
          'event_label': '$(trackName)',
          value: trackValue
        });
      }
    },
    checkgtag() {
      this.isGtagDefined = typeof gtag !== 'undefined';
    },
    validateReq(value, type) {
      let regex = "";
      let result = false;
      let text = value.replace(/\s/g, "");
      switch(type) {
        case 'name':
          regex = /^[a-zA-ZÄäÖöÜüß0-9+*()\-_,.#\/\s]*$/;
          result = (text !== "" && regex.test(text));

          if(!result) {
            regex = /^[A-Za-zÀ-ÖØ-öø-ÿ'-]+$/;
            result = text !== "" && regex.test(text);
          }

        break;
        case 'address':
          regex = /^[a-zA-ZÄäÖöÜüß0-9,\.\(\)\-#\s]*$/;
          result = (text !== "" && regex.test(text));

          if(!result) {
            regex = /^[A-Za-zÀ-ÖØ-öø-ÿ'-]+$/;
            result = text !== "" && regex.test(text);
          }

        break;
        case 'email':
          regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          result = (text !== "" && regex.test(text));
        break;
        case 'currency':
          regex = /^[0-9]{1,16}$/;
          result = (text !== "" && regex.test(text));
        break;
      }
      return result;
    },
    sendContract() {
      if(!this.buttonStatus) {
        this.stepData = {...this.stepData, contractConfirmationError: true, contractreadError: true, contractacceptError: true };
        this.changeContractSteps({step05: this.stepData});
        this.stepData.editButton = true;  
        return;
      } 

      this.trackFormSteps('stepOVERVIEW');
  
      this.commitUpdateSelectedSteps(6);
      this.stepData.editButton = false;

      console.log("Preparing data values");
      let url = process.env.VUE_APP_CSH_SERVICE_URL + '/csh/service/concludeAndEmailContract?language='+this.country;

      let data = {
        "companyInfo": {
          "companyName": this.completeSteps.step02.companyname,
          "street": this.completeSteps.step02.companystreetnumber,
          "postalCode": this.completeSteps.step02.companypostcity,
          "emailId": this.completeSteps.step03.companyemail,
          "mobileNr": this.completeSteps.step03.companymobile,
          "commercialRegistrationNr": this.completeSteps.step02.companyregister,
          "taxId": this.completeSteps.step02.companytax
        },
        "contactPerson": {
          "salutation": this.completeSteps.step03.contacttitle,
          "firstName": this.completeSteps.step03.contactfirstname,
          "lastName": this.completeSteps.step03.contactfamilyname,
          "emailId":  this.completeSteps.step03.contactemail,
          "mobileNr": this.completeSteps.step03.contactmobile
        },
        "paymentDetail": {
          "iban": this.completeSteps.step04.paymentiban,
          "bic": this.completeSteps.step04.paymentbic,
          "debitCharge": (this.completeSteps.step04.paymentmethod==='LASTSCHRIFT')? true:false,
        },
        "companyDetails": {
          "country": (this.country=='DE')? 'DEUTSCHLAND':'ÖSTERREICH',
          "legalForm": this.completeSteps.step02.companylegalform,
          "employeesCount": this.completeSteps.step02.companyemployees,
          "feeCategory": this.completeSteps.step01.companyselection,
          "anualSales": this.completeSteps.step01.companyannualsales,
          "fiscalYearFrom": this.formatDate(this.completeSteps.step01.companyfrom),
          "fiscalYearTo": this.formatDate(this.completeSteps.step01.companyto)
        },
        "contractMediator": {
          "name": (this.completeSteps.step04.partnermediator===true)? this.completeSteps.step04.partnername:'',
          "partnerNumber": (this.completeSteps.step04.partnermediator===true)? this.completeSteps.step04.partnernumber:'',
          "date": (this.completeSteps.step04.partnermediator===true)? this.formatDate(this.completeSteps.step04.partnerdate):''
        }
      }
      
      console.log('data: ', data);
      console.log("Sending...");
      
      axios.post(url,data).then((response) => {
        this.ontimer = false;
        console.log("Sent!", this.ontimer);
        console.log(response);
        
        this.changeContractSteps({response: response});
        
        this.commitUpdateSelectedSteps(7);
        this.trackFormSteps('stepTHANKYOU');

      }).catch((error) => {

        this.commitUpdateSelectedSteps(5);
        this.stepData.editButton = true;
        console.log('error',error);
        this.isSendSucceeded = false;
        this.ontimer = true;
      
      });

    }
  }
}
</script>