<script setup>  

function hideThisMessage() {
  document.getElementById("popup").classList.remove('viewThis');
  document.getElementById("callme").checked = false;
  document.getElementById("dataprotection").checked = false;
  document.getElementById("fullname").value = '';
  document.getElementById("email").value = '';
  document.getElementById("telephone").value = '';
  document.getElementById("message").value = '';
}
</script>

<template>
  <div id="popup">
    <div class="inner">
      <h1>Nachricht gesendet</h1>
      <p>Vielen Dank für Ihre Nachricht. Wir werden uns schnellstmöglich bei Ihnen melden.</p>
      <br><br><br>
      <img class="closebutton" @click="hideThisMessage()" src="https://cogitanda-landingpage.s3.eu-central-1.amazonaws.com/CSH/test/images/parts/closebutton.png"/>
    </div>
  </div>
</template>

<style src="../../../components/parts/formula/thankyou.css"></style>
    
<script>
export default {
}
</script>