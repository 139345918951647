<template>
  <header ref="headerElement">
    <component :is="selectedLanguage" @passLanguage="setLanguage" />
    <router-link to="/" alt="Cyber Sofort Hilfe" class="a_logo">
      <img :alt="locallanguage.cyberLogoTitle" width="689" height="189" class="logo" :src="imagePath + 'header/logo.'+this.language+'.webp?'+updateView"/>
    </router-link>
    <div :class="(this.language=='FR')? 'fr_menu':''" class="menu">
      <div class="item">
        <a :href="(this.language=='DE')? 'http://cogitanda.com/':'http://cogitanda.com/fr/'" :title="locallanguage.cogitandaConnect" target="_blank">{{ locallanguage.aboutus }}</a>
      </div>
      <div class="item" v-if="this.language=='DE'">
        <router-link to="/cyberfacts" :title="locallanguage.cyberfacts">{{ locallanguage.cyberfacts }}</router-link>
      </div>
      <div class="item">
        <a href="/#formula" :title="locallanguage.contact">{{ locallanguage.contact }}</a>
      </div>
    </div>
  </header>
</template>

<style src="../../../components/parts/header/style.css"></style>
    
<script>

import { mapState } from 'vuex'; 

export default {
  data() {
    return {
      header: {
        height: 0
      },
      locallanguage: {
        aboutus: '', contact: ''
      }
    }
  },
  computed: {
    ...mapState(['imagePath',  'updateView', 'language']),
    selectedLanguage() {
        return this.language+'component';
    },
  },
  mounted() {
    setTimeout(() => {
      this.updateHeaderHeight();
    }, 250);
    window.addEventListener('resize', this.updateHeaderHeight);
  },
  beforeDestroy() {
    window.addEventListener('resize', this.updateHeaderHeight);
  },
  methods: {
    updateHeaderHeight() {
      const headerElement = this.$refs.headerElement;
      if(!headerElement) return;
      this.header.height = Math.round(headerElement.offsetHeight * 2);
      this.$emit('headerHeight', this.header.height);
    },
    setLanguage(locallanguage) {
      this.locallanguage = locallanguage
    }
  },
  components: {
      'DEcomponent': require('@/components/parts/header/DE.vue').default,
      'FRcomponent': require('@/components/parts/header/FR.vue').default
  }
}
</script>