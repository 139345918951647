<template>
    <div>
        <div id="step07">
            <div class="inner form">
                <div class="frame">
                    <p>
                        Aufgrund der 5-minütigen Inaktivität beim Ausfüllen des Vertrags wurde Ihnen eine Zeitüberschreitung eingeräumt, um die Sicherheit Ihrer Daten zu gewährleisten.
                    </p>
                    <img class="button" :src="imagePath + 'contractform/fillup-DE01.png?'+updateView" @click="refill" />
                </div>
            </div>
        </div>
    </div>
</template>
  
<style src="../../../../components/tools/contractform/DE/step07.css"></style>
  
<script>
import { mapState } from 'vuex'; 
export default {
    computed: {
        ...mapState(['imagePath', 'updateView', 'country', 'contractSteps']),
    },
    methods: {
        refill() {
            localStorage.setItem('contractform.refill', 1);
            window.location.reload();
        }
    }
}
</script>