<template></template>
<script>
export default {
    created() {
        const language = {
            title: 'L’EQUIPE D’URGENCE CYBER DE COGITANDA', 
            image_desktop: 'crisisdept/fr_crisistab_desktop.png',
            image_mobile: 'crisisdept/fr_crisistab_mobile.png'
        };
        this.$emit('passLanguage', language)
    }
}
</script>