import { createRouter, createWebHistory } from 'vue-router';
import Home from './components/Home.vue';
import cyberfacts from './components/cyberfacts.vue';

const routes = [
  { 
      path: '/', 
    name: 'Home',
    component: Home
  },
  { 
    path: '/cyberfacts',
    name: 'cyberfacts',
    component: cyberfacts
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;