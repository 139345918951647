<template></template>
<script>
export default {
    created() {
        const language = {
            label: "Suite aux prestations de « <b>COGITANDA<sup><small>®</small></sup> Cyber Aide d'Urgence</b> », vous avez la possibilité de demander séparément l'intervention de notre équipe de spécialistes dans le cadre de notre second module « COGITANDA Cyber Gestion de Crise ». Ce second module fait l’objet d’une facturation spécifique"
        };
        this.$emit('passLanguage', language)
    }
}
</script>