<template>
  <FRcontent v-if="this.language=='FR'"/>
  <DEcontent v-else/>
</template>

<script>

import { mapState } from 'vuex'; 

export default {
  computed: {
    ...mapState(['language', 'country']),
  },
  components: {
    'DEcontent': require('@/components/tools/contractform/DE/navigation.vue').default,
    'FRcontent': require('@/components/tools/contractform/FR/navigation.vue').default
  }
}

</script>