<template></template>
<script>
export default {
    created() {
        const language = {
            label: "In Ergänzung zu den Leistungen der <b>COGITANDA<sup><small>®</small></sup> Cyber Sofort Hilfe</b> haben Sie die Möglichkeit, den Einsatz unseres Spezialisten-Teams im Rahmen des Bausteins <b>COGITANDA<sup><small>®</small></sup> Cyber Krisen Management</b> separat zu beauftragen."
        };
        this.$emit('passLanguage', language)
    }
}
</script>